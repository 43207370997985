import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  Menu,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Modal,
  IconButton,
  Tooltip,
  Avatar,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { downArrow, searchIcon } from "../../assetsdashboard/src/assets";
import { closeIcon, downloadIcon } from "../../leadmanagement/src/assets";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { checkMark, editIcon, emptyData, viewIcon } from "./assets";
import { ErrorMessage, Field, Formik } from "formik";
import { companyUpdateSchema } from "../../../components/src/validations";
import Select from "react-select";
import { capitalizeFirstLetter, getCheckMarkIcon, getIntitals, getNullValues, getPageCount, getWrapperClassName } from "../../../components/src/commonUsage";
import { CSVLink } from "react-csv";
import { trackEvent } from "../../../components/src/analytics";
// Customizable Area End

import Companycontactpage2Controller, {
  Props,
} from "./Companycontactpage2Controller";
import TableSkeleton from "../../../components/src/TableSkeleton";

export default class CompanyWeb extends Companycontactpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getListingData('company', 'normal')
    await this.getIndustry()
    await this.getCountries()
    trackEvent("companies","User visits the companies page",{dewey_code:"B3.21",flow:1})
    // await this.fetchEmployeeSize()
  }
  renderCompanyData() {
    return <Box>
      {this.state.listingData?.data.length > 0 ?
        <Box>
          <TableContainer className="lead-table" component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: '#f8f9fa' }}>
                <TableRow style={{ height: '52px' }}>
                  {/* <TableCell className="lead-table-heading" style={{ width: '52px' }}>
                                <Checkbox
                                  size="small"
                                  indeterminate={
                                    this.getIndeterminate()
                                  }
                                  icon={getCheckBoxIcon(false)}
                                  checkedIcon={getCheckBoxIcon(true)}
                                  checked={this.state.selectAll}
                                  onChange={(e) => this.toggleSelectAllCompany(e)}
                                />
                              </TableCell> */}
                  <TableCell className="lead-table-heading extraPadd" style={{ width: '300px' }}>COMPANY NAME</TableCell>
                  <TableCell className="lead-table-heading" style={{ maxWidth: '300px', width: '300px' }}>WEBSITE</TableCell>
                  <TableCell className="lead-table-heading" style={{ width: '150px' }}>COUNTRY</TableCell>
                  <TableCell className="lead-table-heading" style={{ width: '164px' }}>INDUSTRY</TableCell>
                  <TableCell className="lead-table-heading" style={{ width: '302px' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.listingData?.data?.map((row: any, index: number) => (
                  <TableRow key={index} className="row-container"
                  >
                    {/* <TableCell className="lead-table-data-company">
                                  <Checkbox
                                    size="small"
                                    onClick={() => this.toggleSelectRow(row)}
                                    checked={this.state.selectedRows.includes(row)}
                                    icon={getCheckBoxIcon(false)}
                                    checkedIcon={getCheckBoxIcon(true)}
                                  />
                                </TableCell> */}
                    <TableCell className="lead-table-data-company captialize-name extraPadd">
                      <div className="name-wrap">
                        <Avatar className="avtar-block avtar-md gray-bg">{getIntitals(row.company_name)}</Avatar>
                        <span className="purple-text">{getNullValues(capitalizeFirstLetter(row.company_name))}</span>
                      </div>
                    </TableCell>
                    <TableCell className="lead-table-data-row" style={{ maxWidth: '300px', width: '300px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                      {getNullValues(row.company_website)}
                    </TableCell>
                    <TableCell className="lead-table-data-row capitalize-text">
                      {getNullValues(capitalizeFirstLetter(row.country))}
                    </TableCell>
                    <TableCell className="lead-table-data-row capitalize-text">
                      {getNullValues(capitalizeFirstLetter(row.industry))}
                    </TableCell>
                    <TableCell className="lead-table-data-company">

                      <div
                        onFocus={() => this.setEditView()}
                        onBlur={() => this.closeEditView()}
                        className={`edit-cell ${this.state.editView ? "show" : ""}`}
                        style={webStyles.clickEdit}
                      >
                        {
                          row.created_by_partner_id === this.state.listingData.partner_id &&
                          <div className="mr-16 edit-button" data-test-id="selectCompany" onClick={() => this.setSingleCompanyData(row)}>
                            <img src={editIcon} className="mr-4" />Edit
                          </div>
                        }
                        <div className="view-button" onClick={() => this.navigateToLeads(row)}>
                          <img src={viewIcon} className="mr-4" />View referrals
                        </div>
                      </div>

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        :
        <>
          {this.emptyTableDetails()}
        </>
      }
      {
        this.state.listingData.meta?.total_count > 10 &&
        <Box className="table-page-info-block">
          <Box className="pagination-label-wrapper">
            <Typography className="pagination-text">
              Showing {getPageCount(this.state.currentPage, this.state.listingData.meta?.total_count)} out of {this.state.listingData.meta?.total_count} results
            </Typography>
          </Box>
          <Box className="pagination-wrapper">
            <Pagination count={this.state.listingData.meta?.total_pages} onChange={(event: any, page: any) => this.handlePageChange(page, 'company')} page={this.state.currentPage || 1} shape="rounded" />
          </Box>
        </Box>
      }
    </Box>
  }

  emptyTableDetails = () => {
    return <> {
      <Box className="empty-table-block">
        <Box>
          <Table aria-label="simple table">
            <TableHead style={{ background: '#f8f9fa' }}>
              <TableRow>
                <TableCell className="lead-table-heading extraPadd" style={{ width: '300px' }}>COMPANY NAME</TableCell>
                <TableCell className="lead-table-heading" style={{ width: '164px' }}>WEBSITE</TableCell>
                <TableCell className="lead-table-heading" style={{ width: '150px' }}>COUNTRY</TableCell>
                <TableCell className="lead-table-heading" style={{ width: '164px' }}>INDUSTRY</TableCell>
                <TableCell className="lead-table-heading" style={{ width: '402px' }}></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Box>
        <Box className="empty-data-block height-lg">
          <Box className="empty-content">
            <Box className="empty-img">
              <img src={emptyData} />
            </Box>
            <Box className="content">
              <Typography className="title-sm">Get started by adding referrals.</Typography>
              <Typography className="empty-sub-text">Add more referrals to this dashboard and soon this data shall be generated.</Typography>
              <Box className="empty-btn">
                <Button onClick={() => this.navigateToNewDeal()} className="custom-btn primaryLight-btn btn-sm">
                  <Typography className="empty-button-text">
                    Register new referral
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    }
    </>
  }

  sortByDetails = () => {
    return <>
      {
        <Grid className="sortby-wrapper">
          {
            <div>
              <Button
                onClick={(event) => this.handleSortClick(event)}
                type="button"
                className="sort-deal-button"
                id="sort-button"
                aria-haspopup="true"
                disableRipple
              >
                <Typography className="asset-sort-text">
                  Sort by:
                </Typography>
                <Typography className="text-transform sort-value-text">
                  {this.state.sortValue}
                  <label className="dropdown-arrow"> <img src={downArrow} /></label>
                </Typography>
              </Button>
              <Menu
                style={{
                  top: '52px',
                  borderRadius: '8px'
                }}
                id="sort-menu"
                aria-labelledby="sort-button"
                anchorEl={this.state.sortMenu}
                open={this.state.sortMenu}
                onClose={this.handleMenuClose}
                className="sort-popover"
              >
                <MenuItem className="menu-item-text" onClick={() => this.handleSortClose("Latest", 'company')}>Latest {getCheckMarkIcon('latest', checkMark, this.state.sortValue)}</MenuItem>
                <MenuItem className="menu-item-text" onClick={() => this.handleSortClose('Oldest', 'company')}>Oldest {getCheckMarkIcon('oldest', checkMark, this.state.sortValue)}</MenuItem>
                <MenuItem className="menu-item-text" onClick={() => this.handleSortClose('A-Z', 'company')}>A-Z {getCheckMarkIcon('A-Z', checkMark, this.state.sortValue)}</MenuItem>
                <MenuItem className="menu-item-text" onClick={() => this.handleSortClose('Z-A', 'company')}>Z-A {getCheckMarkIcon('Z-A', checkMark, this.state.sortValue)}</MenuItem>
              </Menu>
            </div>
          }
        </Grid>
      }
    </>
  }

  filterSearch = () => {

    const searchQuery = this.state.searchQuery;
    const listingData = this.state.listingData;

    let resultText;

    if (searchQuery.length > 0) {
      if (listingData?.data && listingData.data.length > 0) {
        return <Typography className="search-results-text">{`Search results for "${searchQuery}"`}</Typography>;
      } else {
        return <Typography className="search-results-text">0 Companies</Typography>;
      }
    } else {
      return <Typography className="open-deals-sub-text">{listingData?.meta?.total_count} Companies</Typography>;
    }

  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortMenu } = this.state;
    const sortOpen = Boolean(sortMenu);
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={webStyles.container}>

        <Box className="filter-block-wrapper">
          <Box className="table-search-text filter-inner-wrap">
            <Box className="filter-left-block">
              {
                <>{this.filterSearch()}</>
                // this.state.searchQuery.length > 0 ?
                //   this.state.listingData?.data && this.state.listingData.data.length > 0 ? (
                //     <Typography className="search-results-text">{`Search results for "${this.state.searchQuery}"`}</Typography>
                //   ) : (
                //     <Typography className="search-results-text">0 Companies</Typography>
                //   )
                //   :
                //   <Typography className="open-deals-sub-text">{this.state.listingData?.meta?.total_count} Companies</Typography>
              }
            </Box>
            <Box className="filter-right-block">
              <Grid container style={webStyles.rightAlign}>
                {this.state.listingData.total_companies > 0 ?
                  this.state.listingData?.data.length > 0 ?
                    <>
                      {this.sortByDetails()}
                    </>
                    :
                    <></>
                  :
                  <></>
                }
                <Grid className="mr-16">
                  <TextField
                    className="search-input"
                    fullWidth
                    size="small"
                    placeholder="Search Companies"
                    variant="outlined"
                    onChange={(e) => this.handleSearchValue(e.target.value, 'company')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={searchIcon} className="ml-10" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid>
                  <Box>
                    <Tooltip title="Download CSV" placement="top" arrow>
                      <Button className="download-icon-btn" disabled={!this.state.csvData.data.length}>
                        <CSVLink className="download-icon" filename="company" data={this.state.csvData.data} headers={this.state.csvData.headers?.map((item: any) => {
                          return { label: item.label, key: item.label }
                        })}>
                          <img src={downloadIcon} />
                        </CSVLink>
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Grid>
          {this.state.isLoading ?
            <div>
              {/* <Loader loading={this.state.isLoading} contentLoader={true} /> */}
              {/* skeleton start  */}
              <Box className="skeleton-wrapper">
                <TableSkeleton></TableSkeleton>
              </Box>
              {/* skeleton end  */}
            </div>
            :
            <>
              {this.state.listingData.total_companies > 0 ?
                this.renderCompanyData()
                :
                <>
                  {this.emptyTableDetails()}
                </>
              }
            </>
          }
          <Modal
            open={this.state.companyEdit}
            onClose={() => this.closeEditModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="custom-modal confirmation-modal modal-sm"
            BackdropProps={{
              className: "custom-modal-backdrop"
            }}
          >
            <Box className="modal-body">
              <Box>
                <IconButton disableRipple className="close-btn" onClick={() => this.closeEditModal()}><img src={closeIcon} alt="close" /></IconButton>

                <Typography variant="h4">
                  {this.state.singleCompanyData.company_name}
                </Typography>
              </Box>
              <Box>
                <Typography className="edit-contact-label">
                  View company details here
                </Typography>
                <Formik
                  enableReinitialize
                  initialValues={{
                    id: this.state.singleCompanyData.id,
                    industry: { value: this.state.singleCompanyData.industry, label: this.state.singleCompanyData.industry },
                    company_name: { value: this.state.singleCompanyData.company_name, label: this.state.singleCompanyData.company_name },
                    country: { value: this.state.singleCompanyData.country, label: this.state.singleCompanyData.country },
                    company_website: this.state.singleCompanyData.company_website,
                    employees: { value: this.state.singleCompanyData.company_size || "", label: this.state.singleCompanyData.company_size || "" }
                  }}
                  validationSchema={companyUpdateSchema}
                  onSubmit={(values) => {
                    this.updateCompany(values)
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <Box >
                        <Box className="mt-1">
                          <Box className={getWrapperClassName("custom-search-dropdown-wrapper", this.state.companyNameFocus ? "is-focused check-focus" : "is-focused", formik.values.company_name, this.state.companyNameFocus)}>
                            {/* <Field name="company_name"> */}
                              {/* {(field: any) => (
                                <>
                                  {
                                    field.field.value !== null && */}
                                    <label className="select-label">Company name</label>
                                  {/* } */}
                                  <Select
                                    // {...field}
                                    value={formik.values.company_name}
                                    isDisabled
                                    name="company_name"
                                    styles={selectStyle}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    // onChange={(option) =>
                                    //   formik.setFieldValue('company_name', option)
                                    // }
                                    placeholder="Company name"
                                    className="custom-search-select small-listbox"
                                    // onFocus={() => this.handleFocus('company_name')}
                                    // onBlur={() => this.handleBlur('company_name')}
                                  />
                                {/* </>
                              )}
                            </Field> */}
                            <ErrorMessage className="deal-error-text" name="company_name" component="div" />
                          </Box>
                        </Box>
                        <Box className="mt-1">
                          <TextField
                            name="company_website"
                            size="small"
                            fullWidth
                            id="input-with-icon-textfield"
                            label="Company website"
                            variant="filled"
                            value={formik.values.company_website}
                            onChange={formik.handleChange}
                            error={this.getFormikError(formik.touched.company_website, formik.errors.company_website)}
                            helperText={this.getFormikHelperText(formik.touched.company_website, formik.errors.company_website)}
                          />
                        </Box>
                        <Box className="mt-1">
                          <Box className={getWrapperClassName("custom-search-dropdown-wrapper", this.state.countryFocus ? "is-focused check-focus" : "is-focused", formik.values.country, this.state.countryFocus)}>

                            <label className="select-label">Country</label>
                            <Select
                              value={formik.values.country}
                              options={this.state.countryData}
                              name="country"
                              styles={selectStyle}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              onChange={(option:any) =>
                                formik.setFieldValue('country', option)
                              }
                              placeholder="Country"
                              className="custom-search-select small-listbox"
                              onFocus={() => this.handleFocus('country')}
                              onBlur={() => this.handleBlur('country')}
                            />
                            <ErrorMessage className="deal-error-text" name="country" component="div" />
                          </Box>
                        </Box>
                        <Box className="mt-1">
                          <Box className={getWrapperClassName("custom-search-dropdown-wrapper", this.state.industryFocus ? "is-focused check-focus" : "is-focused", formik.values.industry, this.state.industryFocus)}>

                            <label className="select-label">Industry</label>
                            <Select
                              value={formik.values.industry}
                              options={this.state.industryArray.qualification_listing.industry_listing.map((item: any) => {
                                return { value: item, label: item }
                              })}
                              name="industry"
                              styles={selectStyle}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              onChange={(option:any) =>
                                formik.setFieldValue('industry', option)
                              }
                              placeholder="Industry"
                              className="custom-search-select xsmall-listbox"
                              onFocus={() => this.handleFocus('industry')}
                              onBlur={() => this.handleBlur('industry')}
                            />

                            <ErrorMessage className="deal-error-text" name="industry" component="div" />
                          </Box>
                        </Box>

                        <Box className="mt-1">
                          <Box className={getWrapperClassName("custom-search-dropdown-wrapper", this.state.employeesFocus ? "is-focused check-focus" : "is-focused", formik.values.employees, this.state.employeesFocus)}>

                            <label className="select-label">Number of employees</label>
                            <Select
                              value={formik.values.employees}
                              options={this.state.industryArray.qualification_listing.company_size_listing.map((item: any) => {
                                return { value: item, label: item }
                              })}
                              name="employees"
                              styles={selectStyle}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              onChange={(option:any) => {
                                formik.setFieldValue('employees', option)
                              }}
                              placeholder="Employees"
                              className="custom-search-select xsmall-listbox"
                              onFocus={() => this.handleFocus('employees')}
                              onBlur={() => this.handleBlur('employees')}
                            />
                            <ErrorMessage className="deal-error-text" name="employees" component="div" />
                          </Box>
                        </Box>

                        <Box className="btn-wrap">
                          <Button
                            onClick={() => this.closeEditModal()}
                            fullWidth
                            size="small"
                            variant="contained"
                            type="button"
                            className="custom-btn">Cancel</Button>
                          <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            type="submit"
                            className="custom-btn green-btn">Save</Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>

            </Box>
          </Modal>
        </Grid>

      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '20px 0'
  },
  centerAlign: {
    alignItems: 'center'
  },
  clickEdit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
};
const EditModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 3,
  p: 4,
};
const selectStyle = {
  control: (styles: any) => ({
    ...styles,
    height: 56,
    border: '1px solid #C0C3CE',
    boxShadow: 'none',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #C0C3CE',
      boxShadow: 'none'
    },
    '&::placeholder': {
      marginTop: '10px',
      color: '#83889E',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      fontFamily: "'Rubik', sans-serif",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided: any) => ({
    ...provided,
    marginTop: '15px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
};
// Customizable Area End
