import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  Checkbox,
  Modal,
  Tabs,
  Tab,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./css/style.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { Formik } from 'formik';
import { registrationInviteSchema } from "../../../components/src/validations";
import Loader from "../../../components/src/Loader.web";
import { builderPartner, openEye, visiblityOff, closeIcon } from "../../email-account-login/src/assets";
import { getHideUnHidePassword, getCheckBoxIcon } from "../../../components/src/commonUsage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      {...other}
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistrationInvite extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {
          this.state.loader ?
            <Loader loading={this.state.loader} />
            :
            <Grid className="container-box">
              <Container className="container">
                <Formik
                  enableReinitialize
                  initialValues={{ password: '', reTypePassword: '' }}
                  validationSchema={registrationInviteSchema}
                  onSubmit={(values) => {
                    this.setState({
                      errorMessage: null
                    })
                    this.createPasswordInvite(values)
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          // padding: "10px 0px",
                        }}
                      >
                        <div style={{ textAlign: 'center' }}>
                          <img src={builderPartner} className="login-partner-logo" />
                          <div className="partner-text-container">
                            <Typography className="partner-text">PARTNER DASHBOARD</Typography>
                          </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                          <Typography className="registration-text">
                            Welcome partner!
                          </Typography>
                        </div>
                        <Typography className="registration-sub-text">
                          Thanks for accepting the invite <b>{this.state.email}.</b><br /> Set password to get started
                        </Typography>
                        <Box sx={{ width: "600px", paddingTop: "10px" }}>
                          <Box sx={{ padding: "10px 0px" }}>
                            <TextField
                              name="password"
                              size="small"
                              variant="filled"
                              data-test-id="txtInputPassword"
                              type={this.getTextPassword(this.state.enablePasswordField, "password", "text")}
                              label={"Set password"}
                              fullWidth={true}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              error={this.getFormikError(formik.touched.password, formik.errors.password)}
                              helperText={this.getFormikHelperText(formik.touched.password, formik.errors.password)}
                              InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      edge="end"
                                    >
                                      {
                                        getHideUnHidePassword(this.state.enablePasswordField, <img src={openEye} />, <img src={visiblityOff} />)
                                      }
                                    </IconButton>
                                  </InputAdornment>
                              }}
                            />
                          </Box>
                          <Box sx={{ padding: "10px 0px" }}>
                            <TextField
                              name="reTypePassword"
                              size="small"
                              variant="filled"
                              data-test-id="txtInputRePassword"
                              type={this.getTextPassword(this.state.enableReTypePasswordField, "password", "text")}
                              label={"Confirm password"}
                              fullWidth={true}
                              value={formik.values.reTypePassword}
                              onChange={
                                formik.handleChange
                              }
                              error={this.getFormikError(formik.touched.reTypePassword, formik.errors.reTypePassword)}
                              helperText={this.getFormikHelperText(formik.touched.reTypePassword, formik.errors.reTypePassword)}
                              InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickReTypeShowPassword}
                                      edge="end"
                                    >
                                      {
                                        getHideUnHidePassword(this.state.enableReTypePasswordField, <img src={openEye} />, <img src={visiblityOff} />)
                                      }
                                    </IconButton>
                                  </InputAdornment>
                              }}
                            />
                          </Box>
                          {
                            this.state.errorMessage !== null &&
                            <Box>
                              <Typography className="error-text">
                                {this.state.errorMessage}
                              </Typography>
                            </Box>
                          }
                          <Box className="terms-condition-wrap">
                            <Checkbox
                              size="small"
                              icon={getCheckBoxIcon(false)}
                              checkedIcon={getCheckBoxIcon(true)}
                              data-test-id={"btnCheckAllLeads"}
                              checked={this.state.isTermsConditionsChecked}
                              onChange={this.onCheckBoxClicked}
                            />
                            <div className="terms-txt">By setting up the account, I agree to the</div>
                            <Button disableRipple className="text-btn" onClick={() => this.openTermsConditionsModal()}>
                              terms and conditions.
                            </Button>
                          </Box>
                          <Typography className="error-text">
                              {this.state.isTermsConditions.msg}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              padding: "10px 0px",
                            }}
                          >
                            <Button
                              data-test-id={"btnEmailLogIn"}
                              variant="contained"
                              color="primary"
                              className="registration-button"
                              fullWidth
                              type="submit"
                              disabled={!this.state.isTermsConditionsChecked}
                            >
                              <Typography className="btn-signin-text">
                                Create password {/*UI Engine::From Sketch*/}
                              </Typography>
                            </Button>
                          </Box>
                        </Box>

                      </Box>
                    </form>
                  )}
                </Formik>
              </Container>
              <Modal
                open={this.state.termsConditionsModal}
                onClose={this.closeTermsConditionsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="terms-modal custom-modal"
                BackdropProps={{
                  className: "custom-modal-backdrop terms-modal-backdrop"
                }}
              >
                <Box className="modal-body">
                  <Box className="modal-content">
                    <Box className="title-block">
                      <Box className="left-block">
                        <Typography variant="h4">Terms and conditions</Typography>
                      </Box>
                      <Box className="right-block">
                        <IconButton data-test-id="btnCloseUploadModal1" disableRipple className="close-btn" onClick={() => this.closeTermsConditionsModal()}><img src={closeIcon} alt="close" /></IconButton>
                      </Box>
                    </Box>
                    <Grid>
                      <Box sx={{ width: '100%' }} className="terms-modal-content">
                        <Box className="custom-tabbing-wrap">
                          <Tabs value={this.state.tabValue}
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: "#6200EA"
                              }
                            }}
                            onChange={(event, newValue) => this.handleTabChange(newValue)} aria-label="basic tabs example" className="custom-tab-list">
                            <Tab disableRipple label="General terms and conditions" {...a11yProps(0)} />
                            <Tab disableRipple label="Partner code of conduct" {...a11yProps(1)} />
                            <Tab disableRipple label="Master partner agreement" {...a11yProps(2)} />
                          </Tabs>
                        </Box>
                        <Box className="custom-tab-content-wrap">
                          <TabPanel data-test-id="tabPanel" value={this.state.tabValue} index={0}>
                            <div className="tncProContent">
                              {/* <div className="logoLarge"><img src="../../../../../assets/images/logo-large.jpg" /></div> */}
                              <h3>TERMS AND CONDITIONS</h3>
                              <p>THESE TERMS AND CONDITIONS (THE AGREEMENT) GOVERN CUSTOMER'S ACQUISITION AND USE OF BUILDER SERVICES. CAPITALIZED TERMS HAVE THE DEFINITIONS SET FORTH HEREIN.</p>
                              <p>
                                BY INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT OR ACCESSING OR USING THE SERVICES, YOU ARE AGREEING TO BE BOUND BY ALL TERMS, CONDITIONS AND NOTICES CONTAINED OR REFERENCED IN THIS AGREEMENT. IF THE INDIVIDUAL ACCEPTING THIS
                                AGREEMENT IS ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, SUCH INDIVIDUAL REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERM "CUSTOMER"
                                SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT AGREE TO THIS AGREEMENT, PLEASE DO NOT USE THE SERVICES. EACH PARTY EXPRESSLY AGREES THAT THIS AGREEMENT IS LEGALLY BINDING UPON IT.
                              </p>
                              <p>IF CUSTOMER REGISTERS FOR A FREE TRIAL OF BUILDER SERVICES OR FOR FREE SERVICES, THE APPLICABLE PROVISIONS OF THIS AGREEMENT WILL ALSO GOVERN THAT FREE TRIAL OR THOSE FREE SERVICES.</p>
                              <p>
                                THE SERVICES MAY NOT BE ACCESSED FOR PURPOSES OF MONITORING THEIR AVAILABILITY, PERFORMANCE, OR FUNCTIONALITY, OR FOR ANY OTHER BENCHMARKING OR COMPETITIVE PURPOSES. BUILDER'S DIRECT COMPETITORS ARE PROHIBITED FROM ACCESSING THE
                                SERVICES, EXCEPT WITH BUILDER'S PRIOR WRITTEN CONSENT.
                              </p>
                              <p>THIS AGREEMENT IS EFFECTIVE BETWEEN CUSTOMER AND BUILDER AS OF THE DATE OF CUSTOMER'S ACCEPTING THIS AGREEMENT (THE "EFFECTIVE DATE").</p>
                              <p><strong>CONTENTS OF AGREEMENT.</strong> THIS AGREEMENT CONSISTS OF THE TERMS AND CONDITIONS, THE DEFINITIONS, THE BUILDCARD, AND THE ORDER FORM, IF INCLUDED.</p>
                              <h3>TERMS AND CONDITIONS</h3>
                              <ol>
                                <li>
                                  <h4>USE OF SERVICES AND CONTENT</h4>
                                  <ol>
                                    <li>
                                      <strong>Services.</strong> During the Term, Builder will provide the Services in accordance with the Agreement and Customer may access the Services and use the Services to create any Customer
                                      Application that has material value independent of the Services, in accordance with the Agreement. Unless otherwise provided in the applicable Buildcard, Purchased Services and access to Content are purchased as
                                      subscriptions for the term stated in the applicable Buildcard or in the applicable online purchasing portal. Customer agrees that its purchases are not contingent on any guarantees, promises, or the delivery of any
                                      future functionality, features or timeline, or dependent on any oral or written public comments made by Builder.
                                    </li>
                                    <li>
                                      <strong>Account.</strong> Customer must have an Account to use the Services and is responsible for the information it provides to create the Account, the security of its passwords, and for
                                      any use of its Account. Once Customer has Purchased Services, Builder will deliver and configure a Builder Home Account for Customer, where Customer access and use a project management platform to track and view
                                      customization or project progress relating to their Purchased Services and Buildcard.
                                    </li>
                                    <li>
                                      <strong>Provision of Purchased Services.</strong> Builder will (a) make the Services and Content available to Customer pursuant to this Agreement, and the applicable Buildcards,
                                      Documentation, and Builder Studio One plan, (b) provide applicable Builder basic support for the Purchased Services to Customer at no additional charge, and/or upgraded support if purchased, (c) use commercially
                                      reasonable efforts to make the online Purchased Services available 24 hours a day, 7 days a week, except for: (i) planned downtime (of which Builder shall give advance electronic notice), and (ii) any unavailability
                                      caused by circumstances beyond Builder's reasonable control (the "Force Majeure"), and (d) provide the Services in accordance with laws and government regulations applicable to Builder's provision of its Services to its
                                      customers generally (i.e., without regard for Customer's particular use of the Services), and subject to Customer's and Users' use of the Services in accordance with this Agreement, the Documentation and the applicable
                                      Buildcard.
                                    </li>
                                    <li>
                                      <strong>Usage Limits.</strong> Services and Content are subject to Usage Limits specified in Buildcards and Documentation. If Customer exceeds a contractual Usage Limit, Builder may work with
                                      Customer to seek to reduce Customer's usage so that it conforms to that limit. If, notwithstanding Builder's efforts, Customer is unable or unwilling to abide by a contractual Usage Limit, Customer will execute a
                                      Buildcard for additional quantities of the applicable Services or Content promptly upon Builder's request, and/or pay any invoice for excess usage in accordance with the "Invoicing and Payment" section below.
                                    </li>
                                    <li>
                                      <strong>Acceptable Use.</strong> Customer may use the Service only for its business operations, and in accordance with this Agreement, the Documentation, the Usage Restrictions, the Usage
                                      Limits and the Acceptable Use Policy, available at &lt;<a href="https://builder.ai/terms/acceptable-use-policy" target="_blank">builder.ai/terms/acceptable-use-policy</a>&gt;. In the event
                                      that Builder suspects any breach of the requirements of this Section, Builder may suspend access to the Service without advanced notice, in addition to such other remedies as we may have.
                                    </li>
                                    <li>
                                      <strong>Customer Responsibilities.</strong> Customer will (a) be responsible for Users' compliance with this Agreement, Documentation and Buildcards, (b) be responsible for the accuracy,
                                      quality and legality of Customer Data, the means by which Customer acquired Customer Data, Customer's use of Customer Data with the Services, and the interoperation of any Non-Builder Applications with which Customer
                                      uses Services or Content, (c) use commercially reasonable efforts to prevent unauthorized access to or use of Services and Content, and notify Builder promptly of any such unauthorized access or use, (d) use Services and
                                      Content only in accordance with this Agreement, Documentation, Buildcards and applicable laws and government regulations, and (e) comply with terms of service of any Non-Builder Applications with which Customer uses
                                      Services or Content. Any use of the Services in breach of the foregoing by Customer or Users that in Builder's judgment threatens the security, integrity or availability of Builder's services, may result in Builder's
                                      immediate suspension of the Services, however Builder will use commercially reasonable efforts under the circumstances to provide Customer with notice and an opportunity to remedy such violation or threat prior to any
                                      such suspension. Builder is not responsible or liable for any delay or failure of performance in whole or in part by Customer's delay in performing, or failure to perform, any of its obligations under this Agreement.
                                    </li>
                                    <li>
                                      <strong>Privacy.</strong> Customer is responsible for any consents and notices required to permit (a) Customer's use and receipt of the Services and (b) Builder's accessing, storing, and
                                      processing of data provided by Customer (including Customer Data, if applicable) under the Agreement. Builder's Privacy Policy is available at &lt;
                                      <a href="https://www.builder.ai/terms/privacy-policies" target="_blank">https://www.builder.ai/terms/privacy-policies</a>&gt;.
                                    </li>
                                    <li>
                                      <strong>Protection of Customer Data.</strong> Builder may store certain Customer Data to enable various features and functionality of the Services. Builder will only access or use Customer
                                      Data to provide the Services and data processing for Customer or as otherwise instructed by Customer and will not use it for any other Builder products, services, or advertising. Builder has implemented and will maintain
                                      administrative, physical, and technical safeguards to protect Customer Data. The terms of the data processing addendum at &lt;
                                      <a href="https://www.builder.ai/terms/dpa-scc" target="_blank">https://www.builder.ai/terms/dpa-scc</a>&gt; ("DPA") posted as of the Effective Date are hereby incorporated by reference into
                                      this Agreement. Builder may only change the DPA where such change is required to comply with applicable law, applicable regulation, court order, or guidance issued by a governmental regulator or agency, where such change
                                      is expressly permitted by the DPA, or where such change (i) is commercially reasonable; (ii) does not result in a degradation of the overall security of the Services; (iii) does not expand the scope of or remove any
                                      restrictions on Builder's processing of Customer Data; and (iv) does not otherwise have a material adverse impact on Customer's rights under the DPA.
                                    </li>
                                    <li>
                                      <strong>End Users and Customer Applications.</strong> Builder is not responsible for the content, accuracy or reliability of Customer Applications or Customer Data. Customer's use of the
                                      Customer Applications or Customer Data is entirely at Customer's own risk and is governed by any agreement, terms or other relationship created between the Customer Application and End Users.
                                    </li>
                                    <li>
                                      <strong>Builder Personnel and Contractors.</strong> Builder will be responsible for the performance of its personnel (including its employees and contractors) and their compliance with
                                      Builder's obligations under this Agreement, except as otherwise specified in this Agreement. Builder may from time to time in its discretion engage third parties to perform Services (each, a "Subcontractor") but will
                                      remain liable to Customer for any subcontracted obligations.
                                    </li>
                                    <li>
                                      <strong>Professional Services.</strong> Builder may provide Professional Services to Customers, as agreed between the parties. These will be governed by terms of an addendum or separate
                                      agreement between the parties. Builder will provide any Professional Services set out in a relevant Buildcard in a professional and workmanlike manner, using reasonable skill and care in accordance with industry practice
                                      and any relevant statement of work.
                                    </li>
                                    <li>
                                      <strong>Support and Maintenance Services.</strong> The Buildcard will specify that the Customer is ordering standard or extended levels of support ("Studio One"). Subject to the payment of
                                      the applicable support or plan fees, Builder will support and maintain the Customer Application pursuant to the Studio One found at &lt;
                                      <a href="https://www.buider.ai/terms/studio-one" target="_blank">www.buider.ai/terms/studio-one</a>&gt;. For avoidance of doubt, if Customer does not purchase or renew at least the standard
                                      Studio One, Builder has no obligation whatsoever to provide support, maintenance, updates, or upgrades to the Services or the Customer Application and Customer is responsible for technical maintenance and support of its
                                      Customer Applications.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>BETA, FREE TRIAL OR FREE SERVICES</h4>
                                  <ol>
                                    <li>
                                      <strong>Beta Services.</strong> From time to time, Builder may make Beta Services available to Customer at no charge or with the applicable fees. Customer may choose to try such Beta Services
                                      or not in its sole discretion. Customer acknowledges that these Beta Services might not function as intended and agrees not to use them unless Customer accepts the risks of using pre-release technologies.
                                    </li>
                                    <li>
                                      <strong>Free Trial or Free Accounts.</strong> If Customer registers on Builder's or an Affiliate's website for a free trial or a free account (collectively, "Free Services"), Builder will
                                      make the applicable Free Services available to Customer on a trial basis free of charge until the earlier of (a) the end of the free trial period ("Free Trial"), or (b) the start date of any Purchased Service
                                      subscriptions ordered by Customer for such Service(s), or (c) termination by Builder in its sole discretion. Certain features of the Services may not be available as Free Services. Builder's indemnity does not apply to
                                      use of the Free Services. Additional trial terms and conditions may appear on the trial registration web page. Any such additional terms and conditions are incorporated into this Agreement by reference and are legally
                                      binding. Any data Customer enters into the Free Services, and any customizations made to the Free Services by or for Customer, during Customer's Free Trial will be permanently lost unless Customer purchases a
                                      subscription to the same Services as those covered by the trial, purchases applicable upgraded services, or exports such data, before the end of the trial period.
                                    </li>
                                    <li>
                                      <strong>Free Services.</strong> Builder may make Free Services available to Customer. Use of Free Services is subject to the terms and conditions of this Agreement. In the event of a conflict
                                      between this section and any other portion of this Agreement, this section shall control. Free Services are provided to Customer without charge up to certain limits. Usage over these limits requires Customer's purchase
                                      of additional resources or services. Customer agrees that Builder, in its sole discretion and for any or no reason, may terminate Customer's access to the Free Services or any part thereof. Customer agrees that any
                                      termination of Customer's access to the Free Services may be without prior notice, and Customer agrees that Builder will not be liable to Customer or any third party for such termination. Customer is solely responsible
                                      for exporting Customer Data from the Free Services prior to termination of Customer's access to the Free Services for any reason, provided that if Builder terminates Customer's account, except as required by law Builder
                                      will provide Customer a reasonable opportunity to retrieve its Customer Data. Builder's indemnity does not apply to use of Free Services.
                                    </li>
                                    <li>
                                      <strong>Free Trial and Free Services Disclaimers.</strong> NOTWITHSTANDING THE "REPRESENTATIONS, WARRANTIES, EXCLUSIVE REMEDIES AND DISCLAIMERS" SECTION AND "INDEMNIFICATION BY BUILDER"
                                      SECTION BELOW, THE FREE TRIAL AND FREE SERVICES ARE PROVIDED "AS-IS" WITHOUT ANY WARRANTY AND BUILDER SHALL HAVE NO INDEMNIFICATION OBLIGATIONS NOR LIABILITY OF ANY TYPE WITH RESPECT TO THE FREE TRIAL AND FREE SERVICES
                                      UNLESS SUCH EXCLUSION OF LIABILITY IS NOT ENFORCEABLE UNDER APPLICABLE LAW IN WHICH CASE BUILDER'S LIABILITY WITH RESPECT TO THE FREE TRIAL AND FREE SERVICES SHALL NOT EXCEED $1,000.00. WITHOUT LIMITING THE FOREGOING,
                                      BUILDER AND ITS AFFILIATES AND ITS LICENSORS DO NOT REPRESENT OR WARRANT TO CUSTOMER THAT: (A) CUSTOMER'S USE OF THE FREE TRIAL AND FREE SERVICES WILL MEET CUSTOMER'S REQUIREMENTS, (B) CUSTOMER'S USE OF THE FREE TRIAL
                                      AND FREE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, AND (C) USAGE DATA PROVIDED THROUGH THE FREE TRIAL AND FREE SERVICES WILL BE ACCURATE. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THE
                                      "LIMITATION OF LIABILITY" SECTION BELOW, CUSTOMER SHALL BE FULLY LIABLE UNDER THIS AGREEMENT TO BUILDER AND ITS AFFILIATES FOR ANY DAMAGES ARISING OUT OF CUSTOMER'S USE OF THE FREE TRIAL AND FREE SERVICES, ANY BREACH BY
                                      CUSTOMER OF THIS AGREEMENT AND ANY OF CUSTOMER'S INDEMNIFICATION OBLIGATIONS HEREUNDER.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>MARKETPLACE, NON-BUILDER PRODUCTS AND SERVICES</h4>
                                  <ol>
                                    <li>
                                      <strong>Non-Builder Products and Services.</strong> Builder or third parties may make available (for example, through a Marketplace or otherwise) third-party products or services, including,
                                      for example, Non-Builder Applications and implementation and other consulting services. Any acquisition by Customer of such products or services, and any exchange of data between Customer and any Non-Builder provider,
                                      product or service is solely between Customer and the applicable Non-Builder provider. Builder does not warrant or support Non-Builder Applications or other Non-Builder products or services, whether or not they are
                                      designated by Builder as "certified" or otherwise, unless expressly provided otherwise in a Buildcard or Order Form. Builder is not responsible for any disclosure, modification or deletion of Customer Data resulting from
                                      access by such Non-Builder Application or its provider.
                                    </li>
                                    <li>
                                      <strong>Integration with Non-Builder Applications.</strong> The Services may contain features designed to interoperate with Non-Builder Applications. Builder cannot guarantee the continued
                                      availability of such Service features and may cease providing them without entitling Customer to any refund, credit, or other compensation, if for example and without limitation, the provider of a Non-Builder Application
                                      ceases to make the Non-Builder Application available for interoperation with the corresponding Service features in a manner acceptable to Builder.
                                    </li>
                                    <li>
                                      <strong>Assumption of Risks.</strong> In the event Customer provides Builder with consent, access, control, and usage of Customer Data and any other material shared with and provided by the
                                      Customer to Builder including, without limitation any existing database or other related materials, Customer acknowledges that Customer is voluntarily consenting and authorizing Builder to access and control the Customer
                                      Data and perform services. Customer agrees to accept and assume all risks and/or financial loss arising therefrom, whether caused by the ordinary negligence of Builder or otherwise, waives and releases, and indemnifies
                                      and holds Builder harmless from all claims arising from Builder's access, control or use of Customer Data.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>FEES AND PAYMENT</h4>
                                  <ol>
                                    <li>
                                      <strong>Fees.</strong> Customer will pay all Fees specified in Buildcards, an Order Form, or applicable to your subscription. Except as otherwise specified herein, (i) Fees are based on
                                      Services and Content subscriptions purchased and not actual usage, (ii) payment obligations are non-cancelable and fees paid are non-refundable, and (iii) quantities purchased cannot be decreased during the relevant
                                      subscription term.
                                    </li>
                                    <li>
                                      <strong>Invoicing, Payment and Payment Method.</strong> Customer may pay for the services by using a valid credit card, an electronic fund transfer or a wire transfer or using another method
                                      pre-arranged and pre-approved by Builder including . Customer will provide Builder with valid and updated credit card information, or with a valid purchase order or alternative document reasonably acceptable to Builder.
                                      If Customer provides credit card information to Builder, Customer authorizes Builder to charge such credit card for all Purchased Services, including Fees for the initial subscription term and any renewal subscription
                                      term(s). Such charges shall be made in advance, either annually or in accordance with any different billing frequency stated in the applicable Buildcard or Order Form. If the Buildcard or Order Form specifies that
                                      payment will be by a method other than a credit card, Builder will invoice Customer in advance and otherwise in accordance with the relevant Buildcard or Order Form. Unless otherwise stated in the Buildcard or Order
                                      Form, invoiced fees are due net 30 days from the invoice date. Customer is responsible for providing complete and accurate billing and contact information to Builder and notifying Builder of any changes to such
                                      information. Customer is obligated to pay all applicable fees without any requirement for Builder to provide a purchase order number on Builder's invoice (or otherwise).
                                    </li>
                                    <li>
                                      <strong>Delinquent Payments and Suspension.</strong> Late payments may bear interest at the rate of 1.5% per month (or the highest rate permitted by law, if less) from the payment due date
                                      until paid in full. Customer will be responsible for all reasonable expenses (including attorneys' fees) incurred by Builder in collecting such delinquent amounts. If Customer is late on payment for the Services, Builder
                                      may Suspend the Services or terminate the Agreement for breach.
                                    </li>
                                    <li>
                                      <strong>Invoice Disputes &amp; Refund.</strong> Builder will not exercise its rights under the "Delinquent Payments and Suspension" section above if Customer is disputing the applicable
                                      charges reasonably and in good faith and is cooperating diligently to resolve the dispute. Any invoice disputes must be submitted before the payment due date. If the parties determine that certain billing inaccuracies
                                      are attributable to Builder, Builder will not issue a corrected invoice, but will instead issue a credit memo specifying the incorrect amount in the affected invoice. If the disputed invoice has not yet been paid,
                                      Builder will apply the credit memo amount to the disputed invoice and Customer will be responsible for paying the resulting net balance due on that invoice. To the fullest extent permitted by law, Customer waives all
                                      claims relating to any fees unless claimed within 60 days after the invoice date. Refunds (if any) are at Builder's discretion and will only be in the form of credit for the Services. Nothing in this Agreement obligates
                                      Builder to extend credit to any party.
                                    </li>
                                    <li>
                                      <strong>Taxes.</strong> Builder's fees do not include any taxes, levies, duties or similar governmental assessments of any nature, including, for example, value-added, sales, use or
                                      withholding taxes, accessible by any jurisdiction whatsoever (collectively, "Taxes"). Customer is responsible for paying all Taxes associated with its purchases hereunder. If Builder has the legal obligation to pay or
                                      collect Taxes for which Customer is responsible under this section, Builder will invoice Customer and Customer will pay that amount unless Customer provides Builder with a valid tax exemption certificate authorized by
                                      the appropriate taxing authority. For clarity, Builder is solely responsible for taxes assessable against it based on its income, property and employees.
                                    </li>
                                    <li>
                                      <strong>Financing.</strong> In the event Customer accepts a financing, credit or loan agreement (the "Loan Agreement") offered through Builder to fund part or all of the Fees associated with
                                      this Agreement (the "Financing Option"), Customer shall be solely responsible for all terms, conditions, and obligations under the Loan Agreement and the Financing Option, including Customer's obligation to pay all
                                      amounts specified in the Loan Agreement. In the event Customer breaches, defaults or is delinquent on the Loan Agreement or violates any terms of Loan Agreement or Financing Option, Builder, at its sole discretion, may
                                      suspend or terminate all Services and this Agreement.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>INTELLECTUAL RIGHTS AND LICENSES</h4>
                                  <ol>
                                    <li>
                                      <strong>Reservation of Rights.</strong> Subject to the limited rights expressly granted hereunder, Builder, its Affiliates, its licensors and Content Providers reserve all of their right,
                                      title and interest in and to the Services and Content, including all of their related intellectual property rights. No rights are granted to Customer hereunder other than as expressly set forth herein.
                                    </li>
                                    <li>
                                      <strong>Intellectual Property Rights.</strong> Except as expressly stated in this Agreement, this Agreement does not grant either party any rights, implied or otherwise, to the other's
                                      content or any of the other's intellectual property. Customer owns all Intellectual Property Rights in Customer Data and Customer Applications (excluding the Services and Content), subject to payment of all applicable
                                      Fees. Builder owns all Intellectual Property Rights in the Services (including without limitation any models trained and improvements made to the Services pursuant to Section 1.8). Upon payment of all applicable Fees,
                                      Builder hereby grants Customer a worldwide, perpetual, non-exclusive license to the Building Blocks incorporated or used in the Customer Application, with the right to create derivative works of the Building Blocks for
                                      Internal Business Use.
                                    </li>
                                    <li>
                                      <strong>License by Customer to Builder.</strong> Customer grants Builder, its Affiliates and applicable contractors a worldwide, limited-term license to host, copy, use, transmit, and display
                                      any Non-Builder Applications and program code created by or for Customer using a Service or for use by Customer with the Services, and Customer Data, each as appropriate for Builder to provide and ensure proper operation
                                      of the Services and associated systems in accordance with this Agreement. If Customer chooses to use a Non-Builder Application with a Service, Customer grants Builder permission to allow the Non-Builder Application and
                                      its provider to access Customer Data and information about Customer's usage of the Non-Builder Application as appropriate for the interoperation of that Non-Builder Application with the Service. Subject to the limited
                                      licenses granted herein, Builder acquires no right, title or interest from Customer or its licensors under this Agreement in or to any Customer Data, Non-Builder Application, or such program code.
                                    </li>
                                    <li>
                                      <strong>License by Customer to Use Feedback.</strong> Customer grants to Builder and its Affiliates a worldwide, perpetual, irrevocable, royalty-free license to use, distribute, disclose, and
                                      make and incorporate into its services any suggestion, enhancement request, recommendation, correction, or other feedback provided by Customer or Users relating to the operation of Builder's or its Affiliates' services.
                                    </li>
                                    <li>
                                      <strong>Publicity.</strong> Customer is permitted to state publicly that it is a customer of the Services. Builder may include Customer's name or logos in a list of Builder customers, online
                                      or in promotional materials. Builder may also verbally reference Customer as a customer of the Services. Any use of a party's logos will inure to the benefit of the party holding Intellectual Property Rights to those
                                      logos. A party may revoke the other party's right to use its logos under this Section with written notice to the other party and a reasonable period to stop the use.
                                    </li>
                                    <li>
                                      <strong>U.S. Federal Agency.</strong> The Services were developed solely at private expense and are commercial computer software and related documentation within the meaning of the applicable
                                      Federal Acquisition Regulations and their agency supplements.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>CONFIDENTIALITY</h4>
                                  <ol>
                                    <li>
                                      <strong>Protection of Confidential Information.</strong> As between the parties, each party retains all ownership rights in and to its Confidential Information. The Receiving Party will use
                                      the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind (but not less than reasonable care) to (i) not use any Confidential Information of the Disclosing Party
                                      for any purpose outside the scope of this Agreement and (ii) except as otherwise authorized by the Disclosing Party in writing, limit access to Confidential Information of the Disclosing Party to those of its and its
                                      Affiliates' employees and contractors who need that access for purposes consistent with this Agreement and who have signed confidentiality agreements with the Receiving Party containing protections not materially less
                                      protective of the Confidential Information than those herein. Neither party will disclose the terms of this Agreement or any Buildcard to any third party other than its Affiliates, legal counsel and accountants without
                                      the other party's prior written consent, provided that a party that makes any such disclosure to its Affiliate, legal counsel or accountants will remain responsible for such Affiliate's, legal counsel's or accountant's
                                      compliance with this "Confidentiality" section. Notwithstanding the foregoing, Builder may disclose the terms of this Agreement and any applicable Buildcard to a contractor or Non-Builder Application Provider to the
                                      extent necessary to perform Builder's obligations under this Agreement, under terms of confidentiality materially as protective as set forth herein.
                                    </li>
                                    <li>
                                      <strong>Compelled Disclosure.</strong> The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent compelled by law to do so, provided the Receiving Party
                                      gives the Disclosing Party prior notice of the compelled disclosure (to the extent legally permitted) and reasonable assistance, at the Disclosing Party's cost, if the Disclosing Party wishes to contest the disclosure.
                                      If the Receiving Party is compelled by law to disclose the Disclosing Party's Confidential Information as part of a civil proceeding to which the Disclosing Party is a party, and the Disclosing Party is not contesting
                                      the disclosure, the Disclosing Party will reimburse the Receiving Party for its reasonable cost of compiling and providing secure access to that Confidential Information.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>REPRESENTATIONS, WARRANTIES, EXCLUSIVE REMEDIES AND DISCLAIMERS</h4>
                                  <ol>
                                    <li>
                                      <strong>Representations.</strong> Each party represents that (a) it has validly entered into this Agreement and has the legal power to do so, and (b) it will comply with all laws and
                                      regulations applicable to its provision, receipt, or use of the Services, as applicable.
                                    </li>
                                    <li>
                                      <strong>Builder Warranties.</strong> Builder warrants that during an applicable subscription term (a) Builder will not materially decrease the overall security of the Services, (c) the
                                      Services will perform materially in accordance with the applicable Documentation, and (d) subject to the "Integration with Non-Builder Applications" section above, Builder will not materially decrease the overall
                                      functionality of the Services. For any breach of a warranty above, Customer's exclusive remedies are those described in the "Termination" and "Refund or Payment upon Termination" sections below.
                                    </li>
                                    <li>
                                      <strong>Disclaimers.</strong> EXCEPT AS EXPRESSLY PROVIDED HEREIN, NEITHER PARTY MAKES ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND EACH PARTY SPECIFICALLY
                                      DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. SERVICES PROVIDED FREE OF
                                      CHARGE, CONTENT AND BETA SERVICES ARE PROVIDED "AS IS," AND AS AVAILABLE EXCLUSIVE OF ANY WARRANTY WHATSOEVER.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>MUTUAL INDEMNIFICATION</h4>
                                  <ol>
                                    <li>
                                      <strong>Indemnification by Builder.</strong> Builder will defend Customer against any claim, demand, suit or proceeding made or brought against Customer by a third party alleging that any
                                      Purchased Service infringes or misappropriates such third party's intellectual property rights (a "Claim Against Customer"), and will indemnify Customer from any damages, attorney fees and costs finally awarded against
                                      Customer as a result of, or for amounts paid by Customer under a settlement approved by Builder in writing of, a Claim Against Customer, provided Customer (a) promptly gives Builder written notice of the Claim Against
                                      Customer, (b) gives Builder sole control of the defense and settlement of the Claim Against Customer (except that Builder may not settle any Claim Against Customer unless it unconditionally releases Customer of all
                                      liability), and (c) gives Builder all reasonable assistance, at Builder's expense. If Builder receives information about an infringement or misappropriation claim related to a Service, Builder may in its discretion and
                                      at no cost to Customer (i) modify the Services so that they are no longer claimed to infringe or misappropriate, without breaching Builder's warranties under "Builder Warranties" above, (ii) obtain a license for
                                      Customer's continued use of that Service in accordance with this Agreement, or (iii) terminate Customer's subscriptions for that Service upon 30 days' written notice and refund Customer any prepaid fees covering the
                                      remainder of the term of the terminated subscriptions. The above defense and indemnification obligations do not apply if (I) the allegation does not state with specificity that the Services are the basis of the Claim
                                      Against Customer; (II) a Claim Against Customer arises from the use or combination of the Services or any part thereof with software, hardware, data, or processes not provided by Builder, if the Services or use thereof
                                      would not infringe without such combination; (III) a Claim Against Customer arises from Services under a Buildcard for which there is no charge; or (IV) a Claim against Customer arises from Content, Customer Data, a
                                      Non-Builder Application or Customer's breach of this Agreement or applicable Buildcards.
                                    </li>
                                    <li>
                                      <strong>Indemnification by Customer.</strong> Customer will defend Builder and its Affiliates against any claim, demand, suit or proceeding made or brought against Builder (a) alleging that
                                      the combination of a Non-Builder Application or configuration or instructions provided by Customer and used with the Services or to develop the Customer Application, infringes or misappropriates such third party's
                                      intellectual property rights, (b) arising from (i) Customer's use of the Services, Content, or Customer Application in an unlawful manner or in violation of the Agreement, the Documentation, or Buildcard, (ii) any
                                      Customer Data or Customer's use of Customer Data with the Services or Customer Application, or (iii) a Non-Builder Application provided by Customer, (c) arising out of or pertaining to Customer's or any End User's use of
                                      the Services or Customer Application, or (d) arising out of any aspect of the transaction between Customer's End User (Customer's customer), including but not lmited to refunds, fraudulent transactions, and alleged or
                                      actual violation of laws (each a "Claim Against Builder"), and will indemnify Builder from any damages, losses, liability, settlements, attorney fees and costs, directly or indirectly, provided Builder (a) promptly gives
                                      Customer written notice of the Claim Against Builder, (b) gives Customer the option to control the defense and settlement of the Claim Against Builder (except that Customer may not settle any Claim Against Builder unless
                                      it unconditionally releases Builder of all liability, and except where Customer is unable to reasonably defend the Claim Against Builder), and (c) gives Customer all reasonable assistance, at Customer's expense. Customer
                                      further acknowledges and agrees that by entering into and performing its obligations under this Agreement, Builder is not assuming and shall not be exposed to the business and operational risks associated with Customer's
                                      business.
                                    </li>
                                    <li>
                                      <strong>Exclusive Remedy.</strong> Without affecting either party's terminations rights, this "Mutual Indemnification" section states the indemnifying party's sole liability to, and the
                                      indemnified party's exclusive remedy against, the other party for any third-party claim described in this section.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>LIMITATION OF LIABILITY</h4>
                                  <ol>
                                    <li>
                                      <strong>Limitation of Liability.</strong> IN NO EVENT SHALL THE AGGREGATE LIABILITY OF EACH PARTY TOGETHER WITH ALL OF ITS AFFILIATES ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED THE
                                      TOTAL AMOUNT PAID BY CUSTOMER AND ITS AFFILIATES HEREUNDER FOR THE SERVICES GIVING RISE TO THE LIABILITY IN THE SIX MONTHS PRECEDING THE FIRST INCIDENT OUT OF WHICH THE LIABILITY AROSE. THE FOREGOING LIMITATION WILL
                                      APPLY WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY BUT WILL NOT LIMIT CUSTOMER'S AND ITS AFFILIATES' PAYMENT OBLIGATIONS UNDER THE "FEES AND PAYMENT" SECTION ABOVE.
                                    </li>
                                    <li>
                                      <strong>Exclusion of Consequential and Related Damages.</strong> IN NO EVENT WILL EITHER PARTY OR ITS AFFILIATES HAVE ANY LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT FOR ANY LOST
                                      PROFITS, REVENUES, GOODWILL, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER, BUSINESS INTERRUPTION OR PUNITIVE DAMAGES, WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY, EVEN IF
                                      A PARTY OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF A PARTY'S OR ITS AFFILIATES' REMEDY OTHERWISE FAILS OF ITS ESSENTIAL PURPOSE. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT
                                      PROHIBITED BY LAW.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>TERM AND TERMINATION</h4>
                                  <ol>
                                    <li>
                                      <strong>Term of Agreement.</strong> This Agreement commences on the date Customer first accepts it and continues until all subscriptions hereunder have expired or have been terminated.
                                    </li>
                                    <li>
                                      <strong>Term of Purchased Services.</strong> The term of each subscription shall be as specified in the applicable Buildcard. Except as otherwise specified in a Buildcard, subscriptions,
                                      including any Studio One, will automatically renew for additional one year terms, unless either party gives the other written notice (email acceptable) at least 30 days before the end of the relevant subscription term.
                                      Except as expressly provided in the applicable Buildcard, renewal of promotional or one-time priced subscriptions will be at Builder's applicable list price in effect at the time of the applicable renewal.
                                    </li>
                                    <li>
                                      <strong>Termination for Breach and Bankruptcy.</strong> A party may terminate this Agreement for cause (i) upon 30 days written notice to the other party of a material breach if such breach
                                      remains uncured at the expiration of such period, or (ii) if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the
                                      benefit of creditors.
                                    </li>
                                    <li>
                                      <strong>Suspension of Services.</strong> Without limiting Builder's termination or other rights hereunder, Builder reserves the right to suspend Customer's access to the Services (and any
                                      related services, including but not limited to, development and maintenance and support services, such as Studio One) in whole or in part, without liability to Customer: (i) if Customer's account is forty-five (45) days
                                      or more overdue; (ii) for Customer's breach of this Agreement (including third party Loan Agreement); or (iii) to prevent harm to other customers or third parties or to preserve the security, availability or integrity of
                                      the Services. When practicable, Builder will use reasonable efforts to provide Customer with advance notice of the suspension (email sufficing). Unless this Agreement has been terminated, Builder will cooperate to
                                      restore Customer's access to the Services promptly after Builder verifies that Customer has resolved the issue requiring suspension.
                                    </li>
                                    <li>
                                      <strong>Termination for Inactivity.</strong> Builder reserves the right to terminate the provision of the Services upon 30 days' advance notice if, for a period of 60 days (a) Customer has
                                      not accessed the Service or the Account has had no network activity and (b) such Account has not incurred any fees for such Services.
                                    </li>
                                    <li>
                                      <strong>Effect of Termination.</strong> If the Agreement is terminated, then (a) all rights and access to the Services will terminate (including access to Customer Data, if applicable),
                                      unless otherwise described in this Agreement, and (b) all fees owed by Customer to Builder are immediately due upon receipt of the final invoice or as set forth in the final invoice.
                                    </li>
                                    <li>
                                      <strong>Surviving Provisions.</strong> The sections titled "Free Services," "Fees and Payment," "Proprietary Rights and Licenses," "Confidentiality," "Disclaimers," "Mutual Indemnification,"
                                      "Limitation of Liability," "Effect of Termination," "Surviving Provisions" and "General Provisions" will survive any termination or expiration of this Agreement, and the section titled "Protection of Customer Data" will
                                      survive any termination or expiration of this Agreement for so long as Builder retains possession of Customer Data.
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  <h4>GENERAL PROVISIONS</h4>
                                  <ol>
                                    <li>
                                      <strong>Export Compliance.</strong> The Services, Content, other Builder technology, and derivatives thereof may be subject to export laws and regulations of the United States and other
                                      jurisdictions. Builder and Customer each represent that it is not on any U.S. government denied-party list. Customer will not permit any User to access or use any Service or Content in a U.S.-embargoed country or region
                                      (currently Cuba, Iran, North Korea, Syria, or Crimea) or in violation of any U.S. export law or regulation.
                                    </li>
                                    <li>
                                      <strong>Anti-Corruption.</strong> Neither party has received or been offered any illegal or improper bribe, kickback, payment, gift, or thing of value from an employee or agent of the other
                                      party in connection with this Agreement. Reasonable gifts and entertainment provided in the ordinary course of business do not violate the above restriction.
                                    </li>
                                    <li>
                                      <strong>Entire Agreement and Order of Precedence.</strong> This Agreement is the entire agreement between Builder and Customer regarding Customer's use of Services and Content and supersedes
                                      all prior and contemporaneous agreements, promotions, proposals, or representations, written or oral, published or unpublished in any Builder marketing materials, including its websites and webpages, concerning its
                                      subject matter. The parties agree that any term or condition stated in a Customer purchase order or in any other Customer order documentation (excluding Buildcards) is void. In the event of any conflict or inconsistency
                                      among the following documents, the order of precedence shall be: (1) the applicable Buildcard, (2) this Agreement, and (3) the Documentation. Titles and headings of sections of this Agreement are for convenience only and
                                      shall not affect the construction of any provision of this Agreement.
                                    </li>
                                    <li>
                                      <strong>Relationship of the Parties.</strong> The parties are independent contractors. This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or employment
                                      relationship between the parties. Each party will be solely responsible for payment of all compensation owed to its employees, as well as all employment-related taxes.
                                    </li>
                                    <li><strong>Third-Party Beneficiaries.</strong> There are no third-party beneficiaries under this Agreement.</li>
                                    <li><strong>Waiver.</strong> No failure or delay by either party in exercising any right under this Agreement will constitute a waiver of that right.</li>
                                    <li>
                                      <strong>Severability.</strong> If any provision of this Agreement is held by a court of competent jurisdiction to be contrary to law, the provision will be deemed null and void, and the
                                      remaining provisions of this Agreement will remain in effect.
                                    </li>
                                    <li>
                                      <strong>Assignment.</strong> Neither party may assign any of its rights or obligations hereunder, whether by operation of law or otherwise, without the other party's prior written consent
                                      (not to be unreasonably withheld); provided, however, either party may assign this Agreement in its entirety, without the other party's consent to its Affiliate or in connection with a merger, acquisition, corporate
                                      reorganization, or sale of all or substantially all its assets. Notwithstanding the foregoing, if a party is acquired by, sells substantially all of its assets to, or undergoes a change of control in favor of, a direct
                                      competitor of the other party, then such other party may terminate this Agreement upon written notice. In the event of such a termination, Builder will refund Customer any prepaid fees covering the remainder of the term
                                      of all subscriptions for the period after the effective date of such termination. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their respective successors and permitted
                                      assigns.
                                    </li>
                                    <li>
                                      <strong>Builder Contracting Entity, Notices, Governing Law, and Venue.</strong> The Builder entity entering into this Agreement, the address to which Customer should direct notices under this
                                      Agreement, the law that will apply in any dispute or lawsuit arising out of or in connection with this Agreement, and the courts that have jurisdiction over any such dispute or lawsuit, depend on where Customer is
                                      domiciled.
                                      <table cellSpacing="0" cellPadding="0">
                                        <tr>
                                          <td><strong>If Customer is domiciled in:</strong></td>
                                          <td><strong>The Builder entity entering into this Agreement is:</strong></td>
                                          <td><strong>Notices should be addressed to</strong></td>
                                          <td><strong>Governing law is:</strong></td>
                                          <td><strong>Courts with exclusive jurisdiction:</strong></td>
                                        </tr>
                                        <tr>
                                          <td>The United States of America, including North America (NA) and Latin America (LATAM)</td>
                                          <td>Engineer.ai Corp., a Delaware corporation</td>
                                          <td>26 S. Rio Grande Street, Suite 2072 Salt Lake City, Utah 84101, USA</td>
                                          <td>Delaware and controlling United States federal law</td>
                                          <td>Delaware, U.S.A.</td>
                                        </tr>
                                        <tr>
                                          <td>India, including Asia-Pacific (APAC)</td>
                                          <td>Engineer.ai India Private Limited</td>
                                          <td>77B, Sector 18, IFFCO Road, Gurugram, Haryana India</td>
                                          <td>India</td>
                                          <td>Delhi, India</td>
                                        </tr>
                                        <tr>
                                          <td>All Other, including Europe (EU), and Middle East and North Africa (MENA)</td>
                                          <td>Engineer.ai Global Limited</td>
                                          <td>North West House, 119 Marylebone Rd, London NW1 5PU, United Kingdom</td>
                                          <td>England</td>
                                          <td>England and Wales</td>
                                        </tr>
                                      </table>
                                    </li>
                                    <li>
                                      <strong>Manner of Giving Notice.</strong> The parties may use emails to satisfy written approval and consent requirements under this Agreement, provided notices of termination or an
                                      indemnifiable claim ("Legal Notices") must be clearly identifiable as Legal Notices the day of sending by email. Billing-related notices to Customer will be addressed to the relevant billing contact designated by
                                      Customer. All other notices to Customer will be addressed to the relevant Services system administrator designated by Customer.
                                    </li>
                                    <li>
                                      <strong>Agreement to Governing Law and Jurisdiction.</strong> Each party agrees to the applicable governing law above without regard to choice or conflicts of law rules, and to the exclusive
                                      jurisdiction of the applicable courts above.
                                    </li>
                                  </ol>
                                </li>
                              </ol>
                              <h3>DEFINITIONS</h3>
                              <p><strong>CAPITALIZED TERMS HAVE THE DEFINITIONS SET FORTH BELOW.</strong></p>
                              <p>"Account" means Customer's Builder.ai account.</p>
                              <p>
                                "Affiliate" means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity. "Control," for purposes of this definition, means direct or indirect ownership or control of
                                more than 50% of the voting interests of the subject entity.
                              </p>
                              <p>"Agreement" means this Master Service Agreement.</p>
                              <p>
                                "Beta Services" means Builder services or functionality that Builder is still testing and developing and that may be made available to Customer to try at its option at no additional charge or with applicable fees or subscription,
                                which may be designated as alpha, beta, pilot, limited release, developer preview, non-production, evaluation, demo, pre-release, trial, early access, or by a similar description.
                              </p>
                              <p>
                                "Buildcard" means the Customer specification, ordering document or online order specifying the Services to be provided hereunder that is entered into between Customer and Builder or any of their Affiliates, including any addenda and
                                supplements thereto. By entering into a Buildcard hereunder, an Affiliate agrees to be bound by the terms of this Agreement as if it were an original party hereto. Notwithstanding any language to the contrary in the Buildcard, all
                                Professional Services purchased under an Buildcard are purchased separately from the Services.
                              </p>
                              <p>
                                "Building Blocks" means reusable features provided by the Services to be used in Customer Applications. Builder retains exclusive right, title, and interest, in perpetuity and worldwide, to Building Blocks.
                              </p>
                              <p>
                                "Studio One" means the Services and support and maintenance subscription plan provided by Builder. The Studio One information and benefits are available online at &lt;
                                <a href="https://www.builder.ai/terms/studio-one" target="_blank">https://www.builder.ai/terms/studio-one</a>&gt;.
                              </p>
                              <p>"Builder" means the builder.ai company described in the "Builder Contracting Entity, Notices, Governing Law, and Venue", Section 11.9.</p>
                              <p>
                                "Confidential Information" means all information disclosed by a party ("Disclosing Party") to the other party ("Receiving Party"), whether orally or in writing, that is designated as confidential or that reasonably should be
                                understood to be confidential given the nature of the information and the circumstances of disclosure. Confidential Information of Customer includes Customer Data; Confidential Information of Builder includes the Services and
                                Content, and the terms and conditions of this Agreement and all Buildcards (including pricing). Confidential Information of each party includes business and marketing plans, technology and technical information, product plans and
                                designs, and business processes disclosed by such party. However, Confidential Information does not include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing
                                Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without knowledge of any breach of any
                                obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party. For the avoidance of doubt, the non-disclosure obligations set forth in this "Confidentiality" section apply to Confidential
                                Information exchanged between the parties in connection with the evaluation of additional Builder services.
                              </p>
                              <p>
                                "Content" means information obtained by Builder from publicly available sources or its third-party content providers and made available to Customer through the Services, Beta Services or pursuant to a Buildcard.
                              </p>
                              <p>
                                "Customer" means in the case of an individual accepting this Agreement on his or her own behalf, such individual, or in the case of an individual accepting this Agreement on behalf of a company or other legal entity, the company or
                                other legal entity for which such individual is accepting this Agreement, and Affiliates of that company or entity (for so long as they remain Affiliates) which have entered into Buildcards.
                              </p>
                              <p>"Customer Application" means a software program, mobile application, or website that Customer creates or hosts using the Services.</p>
                              <p>
                                "Customer Data" means electronic data and information submitted by or for Customer to the Services, excluding Content and Non-Builder Applications. Customer Data excludes anonymized data, where personally identifiable information
                                has been removed. Customer Data further includes all materials and information, including documents, data, specifications, software, content, and technology that are provided to Builder (or granting Builder with access and control)
                                by or on behalf of Customer in connection with this engagement.
                              </p>
                              <p>
                                "Documentation" means the applicable Service's "Customer Policies" and its usage guides and policies, as updated from time to time, accessible via &lt;
                                <a href="https://www.builder.ai/terms/customer-policies" target="_blank">https://www.builder.ai/terms/customer-policies</a>&gt; and
                                <a href="https://www.builder.ai/terms/studio-store-faqs" target="_blank">https://www.builder.ai/terms/studio-store-faqs</a> and the Usage Limits provided an order form (or signature page), or through the
                                Builder online portals. For avoidance of doubt, the Documentation and Customer Policies, which includes the Builder Studio Customer Policies and the Builder Studio Store FAQ provided in the above links are hereby incorporated by
                                reference in its entirety into this Agreement.
                              </p>
                              <p>
                                "End User" means an individual that Customer invites or permits to use the Services or a Customer Application. For clarity, End Users may include employees of Customer Affiliates and other third parties.
                              </p>
                              <p>
                                "Force Majeure" means unforeseeable circumstances or events, including, for example an act of God, act of government, flood, fire, earthquake, civil unrest, act of terror, pandemic, strike or other labor problem (other than one
                                involving Builder employees), Internet service provider failure or delay, Non-Builder Application, or denial of service attack.
                              </p>
                              <p>
                                "Free Services" means Services that Builder makes available to Customer free of charge, including Service Level Agreement ("SLA") that are provided free of charge. Free Services exclude Services offered as a free trial and Purchased
                                Services.
                              </p>
                              <p>
                                "Home Account" means an account on Builder Home, a Builder project management platform that provides status information about Customer Buildcard, including the customization progress, invoices and receipts, and provides support
                                mechanism for interactions with Builder through the customization process.
                              </p>
                              <p>"Intellectual Property Rights" means current and future worldwide rights under patent, copyright, trade secret, trademark, and moral rights laws, and other similar rights.</p>
                              <p>
                                "Internal Business Use" means the internal business access and use of the Building Blocks for the Customer Application, and does not include use of the Building Blocks to provide any kind of service/action/assistance or support to
                                any third party, including using or offering any Building Blocks on a service bureau basis or application service provider basis, or sublicense or rent or loan any Building Blocks or otherwise make any Building Blocks available to
                                any third-party.
                              </p>
                              <p>"Loan Agreement" means a third party credit, loan or financing agreement where Customer is offered a financial instrument for financing the purchase of Builder Services.</p>
                              <p>"Malicious Code" means code, files, scripts, agents or programs intended to do harm, including, for example, viruses, worms, time bombs and Trojan horses.</p>
                              <p>"Marketplace" means an online directory, catalog or marketplace of applications that interoperate with the Services and any successor websites.</p>
                              <p>
                                "Non-Builder Application" means Web-based, mobile, offline or other app, services or software functionality that interoperates with a Service, that is provided by Customer or a third party and/or listed on a Marketplace.
                              </p>
                              <p>"Order Form" means any ordering documents, online registration, order descriptions or order confirmations referencing this Agreement, and includes Usage Limits and a subscription plan.</p>
                              <p>
                                "Points" or "Feature Value (FV)" or "FVP" means a method for calculating usage and usage limit based on the feature or Building Block value (FV) that are selected for a Buildcard or used in the Customer Application.
                              </p>
                              <p>
                                "Purchased Services" means Services that Customer or Customer's Affiliate purchases under a Buildcard or online purchasing portal, as distinguished from Free Services or those provided pursuant to a free trial.
                              </p>
                              <p>"Professional Services" means such Builder services as are set out in a separate agreement, addendum, quotation, statement of work, or Buildcard.</p>
                              <p>
                                "Services" means the products and services, including but not limited to Building Blocks, software, apps, websites, and hosting, that are ordered by Customer under a Buildcard or online purchasing portal, provided to Customer, or
                                made available online by Builder, including associated Builder offline or mobile components, as described in the Documentation. "Services" exclude Content and Non-Builder Applications.
                              </p>
                              <p>
                                "Trademark Guidelines" means the Builder guide for use of trade names, trademarks, service marks, logos, and other distinctive brand features of Builder, accessible via &lt;
                                <a href="https://www.builder.ai/terms/trademark-guidelines" target="_blank">https://www.builder.ai/terms/trademark-guidelines</a>&gt; and as updated from time to time.
                              </p>
                              <p>
                                "Usage Limits" means limitations set forth in this Agreement, the Documentation, the Purchased Services, the Buildcard, the Order Form (including signature page order forms), the applicable subscription plan or Builder Care, the
                                purchased packages or plans, including limitations on features, storage, number of apps, support services, development priorities, resource assignments, and replacement services.
                              </p>
                              <p>
                                "Usage Restrictions" means Customer may not (a) make any Service or Content available to anyone other than Customer, or use any Service or Content for the benefit of anyone other than Customer or its Affiliates, unless expressly
                                stated otherwise in a Buildcard or the Documentation, (b) sell, resell, license, sublicense, distribute, rent or lease any Service or Content, or include any Service or Content in a service bureau or outsourcing offering, (c) use a
                                Service or Non-Builder Application to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights, (d) use a Service or Non-Builder
                                Application to store or transmit Malicious Code, (e) interfere with or disrupt the integrity or performance of any Service or third-party data contained therein, (f) attempt to gain unauthorized access to any Service or Content or
                                its related systems or networks, (g) permit direct or indirect access to or use of any Services or Content in a way that circumvents a contractual usage limit, or use any Services to access, copy or use any of Builder intellectual
                                property except as permitted under this Agreement, a Buildcard, or the Documentation, (h) modify, copy, or create derivative works of a Service or any part, feature, function or user interface thereof, (i) copy Content except as
                                permitted herein or in a Buildcard or the Documentation, (j) frame or mirror any part of any Service or Content, other than framing on Customer's own intranets or otherwise for its own internal business purposes or as permitted in
                                the Documentation, (k) except to the extent permitted by applicable law, disassemble, reverse engineer, or decompile a Service or Content or access it to (1) build a competitive product or service, (2) build a product or service
                                using similar ideas, features, functions or graphics of the Service, (3) copy any ideas, features, functions or graphics of the Service, or (4) determine whether the Services are within the scope of any patent.
                              </p>
                              <p>
                                "User" means, in the case of an individual accepting these terms on his or her own behalf, such individual, or, in the case of an individual accepting this Agreement on behalf of a company or other legal entity, an individual who is
                                authorized by Customer to use a Service, for whom Customer has purchased a subscription (or in the case of any Services provided by Builder without charge, for whom a Service has been provisioned), and to whom Customer (or, when
                                applicable, Builder at Customer's request) has supplied a user identification and password (for Services utilizing authentication). Users may include, for example, employees, consultants, contractors and agents of Customer, and
                                third parties with which Customer transacts business
                              </p>
                            </div>
                          </TabPanel>
                          <TabPanel value={this.state.tabValue} index={1}>
                            <div className="tncProContent">
                              <p>
                                Builder® is committed to corporate integrity and doing business honestly and ethically everywhere we operate. This commitment extends to all those with whom we do business, including our customers and business partners. Likewise, we
                                expect our business partners to act honestly, ethically, and legally in all dealings with Builder and our employees, customers, suppliers, business partners, and government officials.
                              </p>
                              <p>
                                This Partner Code of Conduct outlines the minimum standards and practices that Builder expects our partners to follow while conducting business with or on behalf of Builder. It is important that Partners understand their obligations
                                under this Partner Code of Conduct. Therefore, all Builder business partners ("Partners") are required to read and comply with these standards and to ensure that the requirements are communicated, understood, and followed by their
                                employees. These standards are intended to enhance the requirements and terms outlined in your particular Builder partner agreement (whether a Value Added Distributor Agreement, Value Added Reseller Agreement, Referral Agreement,
                                Channel Partner Agreement, Master Agent or Distributor Agreement, Marketplace Agreement, Master Services Agreement, Services Agreement, or another type of partner, supplier, or business agreement). A violation of this Partner Code
                                of Conduct will constitute a breach of your agreement with Builder and may result in termination of your status as a Partner and the applicable agreement.
                              </p>
                              <p>Anti-Corruption</p>
                              <p></p>
                              <ul>
                                <li>
                                  Builder has zero-tolerance for bribery, kickbacks, and corruption. Partners must not offer, give, promise, or authorize the giving of anything of value, such as money, a favor, a fee, a job, entertainment, a gift, a loan, a
                                  rebate, a reward, a payoff, travel expenses, charitable donations, or products, to anyone to obtain any business, to improperly influence any act or decision, or to otherwise gain an improper advantage.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners shall use any Builder assets or funds paid to or entrusted with them (such as non-standard discount, rebates, marketing, or MDF) only for the specific purpose authorized or intended.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must comply with all applicable anti-corruption laws and regulations, including the U.S. Foreign Corrupt Practices Act, the U.K. Bribery Act, India’s Prevention of Corruption Act and Corporate Anti-bribery Code, and all
                                  local anti-bribery laws.
                                </li>
                              </ul>
                              <p></p>
                              <p>Antitrust, Fair Competition and Fraud</p>
                              <p></p>
                              <p>
                                Builder expects Partners to win business through fair and honest competition. Partners must not propose or enter into any agreement (whether formal or informal) with any competitor that harms or reduces competition. For example,
                                agreements to fix or control prices or rates for Builder products or services, boycott suppliers or customers, divide or allocate customers or markets, or coordinate on the bidding process are strictly prohibited.
                              </p>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must not attempt or even discuss such activities with Builder employees, other Builder business partners, or representatives of other companies. In addition, Partners must not share competitively sensitive information
                                  such as price, profit or profit margin, exchange rates, costs, credit terms, or quotes for a specific customer’s business with any of their competitors. Partners must compete fairly in their dealings with customers.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must comply with all applicable laws governing exclusionary contracts and discounts, below cost pricing and predatory practices, and false advertisement and business disparagement. Partners must not make any false
                                  representations to anyone or engage in other misleading or deceptive conduct in relation to any Builder product, service, or transaction.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners must comply with all applicable antitrust and competition laws and regulations, including all U.S. and local laws, and all competition law program rules published by Builder.</li>
                                <li>
                                  Partners shall not provide information to Builder that is false, intentionally misleading, or otherwise likely to deceive Builder or perform any action to gain an unfair advantage, including without limitation, impersonating any
                                  person, performing services for another, circumventing or working around security and control features of Builder products and services, misrepresenting (through misleading or omissions) the identify, skills, services, hours, or
                                  affiliation with any person or organization.
                                </li>
                              </ul>
                              <p><br /></p>
                              <p>Financial Integrity and Accounting</p>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must maintain accurate and complete books and records related to their agreements with Builder, all transactions related to sales of Builder products and services, and all transactions or other expenditures with respect
                                  to any Builder-related business.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners must not facilitate the creation or preparation of any false, misleading, or inaccurate document relevant to Builder business.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Business records must be maintained in accordance with record retention policies and all applicable laws and regulations, including where relevant, Sarbanes-Oxley, Internal Revenue Service, and tax requirements.</li>
                              </ul>
                              <p></p>
                              <p>Conflicts of Interest</p>
                              <p></p>
                              <ul>
                                <li>Partners must avoid situations, activities, and relationships that may result in an inappropriate conflict or the appearance of a conflict with Builder’s interests.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must notify Builder if there is an actual or potential conflict of interest with Builder or any of its employees and other business partners. While it is not possible to list every conceivable conflict of interest, a
                                  Partner must disclose to Builder if the Partner (or someone employed by the Partner) is employed by or has a financial interest in Builder or with other partners, or if Partner (or someone employed by the Partner) has a family
                                  member who is employed by or has a financial interest in Builder or with other partners.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Builder business partners acknowledge that Builder employees and their family members may not hold any significant economic interest in any entity that does business with Builder, and Builder business partners shall avoid such
                                  relationships with Builder employees.
                                </li>
                              </ul>
                              <p></p>
                              <p>Export Controls</p>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must comply with all U.S. and local export, re-export, and economic sanctions laws and regulations, restrictions reflected in relevant Builder licenses, agreements, or program materials, and any other trade compliance
                                  restrictions applicable to Builder-provided products and services, regardless of whether the product or service is Builder-branded.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must not—directly or indirectly—export, re-export, or transfer Builder products, or make Builder products or services available, to restricted destinations, to restricted end users, or for restricted end uses without
                                  first obtaining all approvals or licenses required under U.S. or other applicable laws and regulations.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must not provide or facilitate the submission of misleading or inaccurate information concerning end destinations, end users, and potential end uses of Builder products, and they must promptly notify Builder if they
                                  learn a product or service has been made available to someone who is ineligible to receive it under applicable law.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners are responsible for understanding how the export control laws and regulations apply and for monitoring changes to them.</li>
                              </ul>
                              <p></p>
                              <p>Government Customers</p>
                              <p></p>
                              <ul>
                                <li>
                                  Certain activities that may be customary and appropriate when dealing with commercial or non-government customers may be improper or even illegal when dealing with government or government-owned or government-controlled
                                  customers (including prime and lower tier contractors) at all levels, including federal, state, and local jurisdictions.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must not directly or indirectly lobby on behalf of Builder without written authorization from Builder. This prohibition includes attempts to influence legislation, regulations, appropriations, rulemaking, executive
                                  orders, ratemaking, or other government policies or programs.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners are responsible for understanding and complying with all laws, rules, and regulations that apply to government contracting and interactions with government officials and employees, including regulations governing
                                  procurement lobbying and attempts to influence the negotiation, award, or administration of government contracts, grants, and other such procurements, as well as matters such as loans, permits, and licenses. This includes, for
                                  example, understanding and complying with all lobbying registration and reporting obligations in each jurisdiction where the Partner is doing business.
                                </li>
                              </ul>
                              <p></p>
                              <p>Insider Trading</p>
                              <p></p>
                              <ul>
                                <li>Partners must comply with all applicable U.S. and local insider trading and securities laws governing transactions in the securities of Builder and its investors and partners.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners may sometimes receive material, non-public information about Builder and Builder customers, vendors, suppliers, distributors, or other companies engaged in business or contemplating a transaction with Builder.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must not use such information for the personal benefit of the Builder partner, its employees, or any other person. Non-public information is also subject to the restrictions discussed in the section of this Partner Code
                                  titled "Data Protection and Confidentiality."
                                </li>
                              </ul>
                              <p></p>
                              <p>Data Protection and Confidentiality</p>
                              <p></p>
                              <ul>
                                <li>
                                  Partners must protect Builder’s and its customers’ information, not disclose it to any unauthorized third party, and use it only for business with Builder or Builder’s customers pursuant to applicable agreements and data privacy
                                  and security laws.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>
                                  Partners also must comply with all government data use restrictions including those pertaining to the International Traffic in Arms Regulations, classified materials, and controlled unclassified technical data. Builder’s
                                  software, documentation, or other materials are considered Builder confidential information and may not be reproduced without the express written consent of Builder.
                                </li>
                              </ul>
                              <p></p>
                              <p>Intellectual Property</p>
                              <p></p>
                              <ul>
                                <li>Partners must respect Builder’s intellectual property rights and the intellectual property rights of others.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners must comply with all applicable agreements and U.S., local, and all other applicable laws relating to Builder’s intellectual property rights.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners may not misuse any Builder trademarks or copyrighted materials or improperly disclose Builder’s confidential information or trade secrets.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners also are prohibited from infringing on the intellectual property rights of Builder or third parties in any manner related to their Builder partner status.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners must notify Builder of any unauthorized use of Builder’s copyrights, trademarks, trade secrets, proprietary, or confidential information by a third party.</li>
                              </ul>
                              <p><br /></p>
                              <p>Human Rights, Labor Standards, and Fair Labor Practices</p>
                              <p></p>
                              <ul>
                                <li>
                                  Builder is committed to protecting and promoting human rights wherever it does business. Builder expects Partners to (a) support internationally recognized human rights and treat their own employees fairly, with dignity and
                                  respect, (b) comply with all applicable laws and regulations regarding health and safety in the workplace, wages, and benefits, and (c) work toward the eradication of human trafficking and slavery and the elimination of child
                                  labor in their operations and in their supply chains.
                                </li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Builder expects Partners to support fair labor practices, including the freedom to associate, and to create a work environment that is free from harassment and discrimination.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Partners must comply with all relevant slavery and human trafficking laws in the countries in which they do business.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Builder may require additional written certification of compliance with these laws from Partners.</li>
                              </ul>
                              <p></p>
                              <ul>
                                <li>Builder condemn forced labor and will not knowingly work with Partners who engage in these practices or the use of incarcerated labor.</li>
                              </ul>
                              <p><br /></p>
                              <p>Environmental Laws</p>
                              <p></p>
                              <ul>
                                <li>Partners must conduct their operations in compliance with all applicable environmental laws, regulations, and standards.</li>
                                <li>Partners provided residential facilities to the workers must be safe and hygiene.</li>
                              </ul>
                              <p></p>
                              <p>Violation and Reporting</p>
                              <p></p>
                              <ul>
                                <li>
                                  The Partner Code of Conduct is a contractual obligation. Minor breaches of the Partner Code of Conduct will, in the first instance, be dealt with informally by the Builder partner and channel team. Where there is evidence of
                                  serious, deliberate or continued breach of the Partner Code of Conduct, a formal action may be taken against the Partner concerned, including suspension or termination of the Partner’s business relationship or agreement, at the
                                  sole discretion of Builder.
                                </li>
                                <li>
                                  If you become aware of any potentially improper conduct by Builder employee, agent, consultant, or partner, you should report this activity to the Builder Business Ethics and Compliance Team at <a href="mailto:compliance@builder.ai">compliance@builder.ai</a> .
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel value={this.state.tabValue} index={2}>
                            <div className="tncProContent">
                              <div>
                                <div>
                                  <p>
                                    This Builder Master Partner Agreement ("<strong>MPA</strong>" or "<strong>Partner Agreement</strong>" or "<strong>Partner Program</strong>") and the<strong>Program Policies</strong> referenced herein (collectively, the
                                    "<strong>Agreement</strong>") governs your access to the partner community and participation in the Builder partner program. by accepting this MPA, either by clicking a box indicating your acceptance or otherwise
                                    electronically indicating acceptance, you agree to the terms of this Agreement. If you are entering into this Agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such
                                    entity to these terms and conditions, in which case the terms "you" or "your" shall refer to such entity. you and Builder ("<strong>Builder</strong>") are each a "<strong>Party</strong>" and collectively
                                    "<strong>Parties</strong>" to this Agreement.
                                  </p>
                                  <p>
                                    You may not access the Partner Community or participate in the Partner Program if you do not agree with these terms and conditions, or (ii) are or become (in whole or in part) a direct competitor of Builder except with
                                    Builder's prior written consent. Further, you may not access the Partner Community or join the Partner Program for purposes of monitoring Builder or its services, their performance or functionality, or for any other
                                    benchmarking or competitive purposes.
                                  </p>
                                  <p><strong>Contents of Agreement.</strong> This Agreement consists of this Signature Page, Schedule A (Terms and Conditions), Schedule B (Definitions) and Schedule C [Program/Partner Type Agreement].</p>
                                  <p>
                                    <strong>Program or Partner Type</strong>. Partner may request to join a specific Builder Partner Program (or Partner Type), which requires a separate addendum or agreement. Partner Program or Partner Type, if authorized,
                                    checked and included, shall be provided and form a part of this Agreement.
                                  </p>
                                  <p>
                                    <strong>
                                      SCHEDULE A: TERMS AND CONDITIONS<br />
                                      <br />
                                    </strong>
                                  </p>
                                  <ol>
                                    <li>
                                      <h4>Program Overview</h4>
                                      <ul>
                                        <li>
                                          <strong>Enrollment. </strong>To participate in the Partner Program, Partner must be enrolled in a Program Type. To enroll in a Program Type, Partner must fulfill the Participation Qualifications set forth in the
                                          applicable Program Type's <strong>Program Policies</strong> as provided in Schedule C and be accepted for the applicable Program Type by Builder.
                                        </li>
                                        <li>
                                          <strong>Fees. </strong>Participation in the Partner Program, including assignment to certain Partner Types and Partner Tiers or the receipt of certain Program Benefits, may be subject to Program Fees as described in
                                          the Program Policies. Payment obligations are non-cancelable and fees paid are non-refundable.
                                        </li>
                                        <li>
                                          <strong>Services, Compliance and Technical Training. </strong>Partner's sales representatives must be reasonably capable of effectively delivering Builder's value proposition and must be generally knowledgeable about
                                          the Services and their interfaces, advantages and high-level functionality. Other requirements regarding Partner Services, compliance and technical training vary by Program Type, and are described in the Program
                                          Policies.
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <strong>Partner Affiliates. </strong>Partner Affiliates may hold themselves out as Partners of Builder solely as expressly permitted pursuant to the Program Policies for such Partner Program, and only for the
                                          purposes of such Partner Program, subject to Partner's obligations with respect to the disclosure of third parties in Section 2.6 (Disclosure of Third Parties). Unless otherwise agreed by the Parties in writing,
                                          Partner is responsible for ensuring any Partner Affiliate holding itself out as a Partner of Builder complies with the terms of this MPA and first completes Builder's compliance and due diligence forms and receives
                                          Builder's written approval of such forms (before holding itself out as a Partner of Builder), available upon request by logging a case in the Partner Community. Participation by Partner Affiliates in this manner may
                                          be denied or revoked at any time by Builder in Builder's sole discretion. Alternatively, each Partner Affiliate that desires to be a member of the Partner Program must separately agree to this Agreement and take such
                                          other steps to enroll in the applicable Program Type as are specified in the Program Policies.
                                        </li>
                                        <li>
                                          <strong>Opt-in to Marketing. </strong>Partner's participation in the Partner Program will serve as an opt-in to receive Builder's marketing communications. Partner will be presumed to have provided appropriate
                                          notices and have obtained appropriate consents, if required, from any persons or Partner Users who are signed up to the Partner Program on Partner's behalf. Partner may elect to opt-out from receiving Builder's
                                          marketing materials by contacting Builder directly.
                                        </li>
                                        <li>
                                          <strong>Partner-Sponsored Co-Marketing Activities and Expenses. </strong>Partner may not sponsor and will not be reimbursed for any co-marketing activities or events, unless otherwise agreed in writing by Builder.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <h4>Intellectual Property Ownership</h4>
                                      <ul>
                                        <li>
                                          <strong>Technology. </strong>Subject to the limited licenses and rights set forth in this Agreement, nothing in this Agreement transfers or assigns to either Party any of the other Party's intellectual property or
                                          other proprietary rights in the other Party's technology, products or services. The intellectual property and other proprietary rights in Builder's technology, products and services, including without limitation the
                                          Marketplace, the Services and the Partner Services, are defined herein as "<strong>Builder's Property</strong>."
                                        </li>
                                        <li>
                                          <strong>Builder Trademarks. </strong>Builder's marks, including those identified in Builder's Partner Branding Guidelines, and otherwise used on Builder's websites, are Builder's trademarks or service marks and may
                                          not be used in any manner except as expressly permitted in this Agreement or the applicable Program Policies, or with Builder's prior written consent. Consistent with Builder's trademark rights and usage policies,
                                          Partner shall not incorporate "Builder," (unless permitted by the Builder Partner Branding Guidelines) or any other Builder mark or brand in any trade name, brand name, domain name, or other source identifying term.
                                          Partner shall not bid on or purchase any keyword which is Builder's trademark, including without limitation BUILDER, BUILDER.AI, BUILDER STUDIO PRO, BUILDER STUDIO STORE, BUILDER CLOUD, BUILDER NOW, BUILDER HIVE, AND
                                          CAPACITY NETWORK, (such as, for example, Google AdWords) except with Builder's prior written consent. Partner may not publish any advertisement that includes any Builder trademarks without prior review and approval
                                          of Partner's proposed ad and/or related website by Builder's Legal and Marketing teams. Partner may forward requests for review and approval to legal@builder.ai
                                        </li>
                                        <li>
                                          <strong>Partner Trademark License. </strong>Partner grants Builder a nonexclusive, nontransferable, non- sub licensable, royalty-free license to use, for the purpose of identifying and promoting Partner's
                                          participation in Builder's Partner Programs and in connection with Builder's rights, duties and obligations under this Agreement, Partner's marks including Partner's company name, and, if applicable, Partner's
                                          Marketplace publisher name and any Marketplace listing names, and any other marks or logos associated therewith or otherwise used by Partner within the Builder ecosystem ("<strong>Partner's Marks</strong>"). Partner
                                          may withdraw its approval of any use of the Partner's Marks at any time in its sole discretion upon written notice to Builder, which withdrawal shall be effective promptly but&nbsp; in no case more than thirty (30)
                                          days from the date of Partner's notice sent in accordance with Section 16.2 (Manner of Giving Notice) below, provided that no such withdrawal will require the recall of any previously published or distributed
                                          materials.
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <strong>Competitive Applications. </strong>Subject to Builder's and Partner's respective rights and obligations under this Agreement, Builder acknowledges that Partner and/or other parties may develop and publish
                                          applications that are similar to or otherwise compete with the Services or other Builder applications, products and services, and Partner acknowledges that Builder and/or other parties may develop and publish
                                          applications that are similar to or otherwise compete with Partner's Applications, products or services.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <h4>Restrictions</h4>
                                      <p>
                                        <strong>Restrictions on Use of the Partner Services. </strong>Partner is responsible for all activities that occur in Partner User accounts, and for its and Partner Users' compliance with this Agreement. In no event
                                        shall Partner:
                                      </p>
                                      <ul>
                                        <li>
                                          sell, resell, license, sublicense, distribute, make available, rent or lease or otherwise commercially exploit to any third party (except as expressly provided in this BPPA) the Partner Services in any way;&nbsp;
                                        </li>
                                        <li>
                                          use the Partner Services or a Non-Builder Application to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy
                                          rights,&nbsp;
                                        </li>
                                        <li>
                                          use the Partner Services or a Non-Builder Application to send or store any code, files, scripts, agents or programs intended to do harm, including, for example, viruses, worms, time bombs and Trojan horses;&nbsp;
                                        </li>
                                        <li>modify or make derivative works based upon the Partner Services;&nbsp;</li>
                                        <li>create Internet "links" to the Services or Partner Services, or "frame" or "mirror" them;&nbsp;</li>
                                        <li>
                                          permit direct or indirect access to or use of any Services or Partner Services in a way that circumvents a contractual usage limit, or use any of the Partner Services to access or use any of our intellectual property
                                          except as permitted under this Agreement;&nbsp;
                                        </li>
                                        <li>
                                          interfere with or disrupt the integrity of performance of the Partner Services or the data contained therein; (viii) access Partner Services in order to build a competitive product or service or to benchmark with a
                                          non-Builder product or service or&nbsp;
                                        </li>
                                        <li>reverse engineer the Partner Services;&nbsp;</li>
                                        <li>share data or content from the Partner Services with Builder competitors;&nbsp;</li>
                                        <li>attempt to gain un authorized access to any Partner Services or Content or related systems or networks;&nbsp;</li>
                                        <li>copy Partner Services or any part, feature, function, or user interface thereof; or&nbsp;</li>
                                        <li>recruit or market directly to other Partner users using data, content or contact information obtained through the Partner Community.&nbsp;</li>
                                      </ul>
                                      <p>
                                        Partner's intentional violation of the foregoing, or any use of the Partner Services in breach of this Agreement by Partner that in Builder's judgment imminently threatens the security, integrity or availability of
                                        Builder's services, may result in Builder's immediate suspension of the Partner Services. Builder will use commercially reasonable efforts under the circumstances to provide Partner with an opportunity to remedy such
                                        violation or threat prior to any such suspension.
                                      </p>
                                      <ul>
                                        <li>
                                          <strong>Restrictions on use of the Services. </strong>Partner acknowledges and agrees that Partner's use of any Services provided to Partner in connection with Partner's activities hereunder is governed by the terms
                                          of the Master Service Agreement found at <a href="/terms/msa">builder.ai/terms/msa</a> unless Partner has a written master service agreement executed by Builder for such Services as referenced in the Documentation, in
                                          which case such written master subscription agreement between the parties will govern (as applicable, the "MSA").
                                        </li>
                                        <li>
                                          <strong>Additional Restrictions. </strong>Without affecting any other restrictions set forth in the MSA and this MPA, Partner's use of any Program Benefits, including Builder's Property provided to Partner hereunder,
                                          is subject to additional restrictions. Specifically, Partner may <em>not</em>:
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>Remove or modify any program markings or any notice of Builder's or Builder's licensors' proprietary rights;</li>
                                        <li>
                                          Make the Services, any materials delivered hereunder, or any materials resulting from the Services available in any manner to any third party for use in the third party's business operations, other than as expressly
                                          permitted herein or in the Program Policies for Partner's assigned Program Type;
                                        </li>
                                        <li>Use Builder's Property in a manner that misrepresents Partner's relationship with Builder or is otherwise misleading or that reflects negatively on Builder or may harm Builder's rights therein;</li>
                                        <li>
                                          Modify in any way any of Builder's trademarks and/or associated logos (e.g., by inserting Partner's company or brand name inside Builder's proprietary logos OR by co-branding products or services by blending
                                          Builder's corporate logo with Partner's corporate logo without Builder's permission);
                                        </li>
                                        <li>Use or duplicate Builder's Property provided to Partner for any purpose other than as specified in this Agreement or make Builder's Property available to unauthorized third parties;</li>
                                        <li>
                                          Use Builder's Property for Partner's own internal business operations, or use or make Builder's Property available in any manner to any third party for use in the third party's business operations or for any other
                                          commercial or production use, other than as expressly permitted in this Agreement applicable to Partner's assigned Program Type and/or Partner Tier; or
                                        </li>
                                        <li>
                                          Use the Services, Partner Community, Marketplace or Builder Property in violation of Builder's Acceptable Use Policy found at  <a href="/terms/acceptable-use-policy">builder.ai/terms/acceptable-use-policy</a>as may be
                                          updated from time to time.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <h4>Compliance</h4>
                                      <ul>
                                        <li><strong>Compliance with Laws.</strong></li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <strong>Compliance with Applicable Laws. </strong>In connection with this Agreement, Partner shall comply, and shall ensure its employees, officers, directors, and any third parties performing activities on Partner's
                                          behalf comply, with all applicable laws and regulations, including, without limitation, trademark and copyright laws and ICANN policies and procedures governing domain names ("Applicable Laws") and shall not engage
                                          in any deceptive, misleading, illegal or unethical marketing activities, or activities that otherwise may be detrimental to Builder, Customers, the Services, or to the public. Builder shall comply with Applicable
                                          Laws that are applicable to Builder generally (i.e., without regard to Partner's and/or any Customer's particular use of the Services or Partner Services) in its performance of its obligations hereunder.
                                        </li>
                                        <li>
                                          <strong>Compliance with Anti-Corruption Laws. </strong>Without limiting Section 4.1 above, in connection with the Agreement, Partner, and all employees, officers, and directors, and any third parties working for
                                          Partner or performing activities on Partner's behalf, (1) will comply with the U.S. Foreign Corrupt Practices Act, the U.K. Bribery Act, and the Prevention of Corruption Act (1988, India) and other applicable
                                          anti-corruption laws and regulations (collectively, the "<strong>Anti-Corruption Laws</strong>"); and (2) shall keep accurate books, accounts, and records. It is the intent of the Parties that no payments or
                                          transfers of anything of value shall be made in connection with this Agreement that have the purpose or effect of public, commercial or other bribery, or acceptance of or acquiescence in extortion, kickbacks, or
                                          other unlawful or improper means of obtaining business or any improper advantage.
                                        </li>
                                        <li>
                                          <strong>Consequences of Violation. </strong>Partner hereby acknowledges and agrees, Builder may terminate or suspend this Agreement immediately by written notice without any liability to Partner: (1) upon any
                                          violation by Partner of this Section 4; or (2) circumstances causing Builder to believe, in good faith, that Partner, or any of its owners, directors, employees, or third parties (including sub-contractors,
                                          sub-distributors, integrators, or other third parties), has engaged in illegal conduct or unethical business practices, including any potential violations of the Anti- Corruption Laws. Termination or suspension by
                                          Builder under this section shall be in addition to, and not in lieu of, Builder's other legal rights and remedies. If Builder terminates or suspends the Agreement under this section, Builder may suspend or withhold
                                          any payments to Partner hereunder. Builder will not be liable for any claims, losses, or damages arising from or related to failure of Partner to comply with the Anti-Corruption Laws or this Agreement or related to
                                          the termination or suspension of this Agreement under this clause, and Partner will indemnify and hold Builder harmless against any such claims, losses, or damages.
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <strong>Reporting Potential Violations. </strong>Partner agrees that it shall promptly inform Builder's Legal Department (legal@builder.ai) in writing should it or any of its officers, directors, or employees learn
                                          of, or suspect, any act or circumstance that may violate applicable laws in connection with this Agreement.
                                        </li>
                                        <li>
                                          <strong>Export Compliance. </strong>Builder and Partner each represents that it is not named on any U.S. government denied-parties list. Neither party will access or use any Partner Services, Program Benefits or
                                          Confidential Information provided to it hereunder in a U.S.-embargoed country or region (currently the Crimea region, Cuba, Iran, North Korea, Sudan or Syria) or in violation of any U.S. export law or governmental
                                          regulation.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <h4>Confidentiality</h4>
                                      <ul>
                                        <li>
                                          <strong>Protection of Confidential Information. </strong>The Receiving Party will use the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind (but not less
                                          than reasonable care) to (i) not use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement and (ii) except as otherwise authorized by the Disclosing Party in
                                          writing, limit access to Confidential Information of the Disclosing Party to those of its and its Affiliates' employees and contractors who need that access for purposes consistent with this Agreement and who have
                                          signed confidentiality agreements with the Receiving Party containing protections not materially less protective of the Confidential Information than those herein. Neither party will disclose the terms of this
                                          Agreement or any Order Form to any third party other than its Affiliates, legal counsel and accountants without the other party's prior written consent, provided that a party that makes any such disclosure to its
                                          Affiliate, legal counsel or accountants will remain responsible for such Affiliate's, legal counsel's or accountant's compliance with this "Confidentiality" section.
                                        </li>
                                        <li>
                                          <strong>Compelled Disclosure. </strong>The Receiving Party may disclose Confidential Information of the Disclosing Party if it is compelled by law to do so, provided the Receiving Party gives the Disclosing Party
                                          prior notice of such compelled disclosure (to the extent legally permitted) and reasonable assistance, at the Disclosing Party's cost, if the Disclosing Party wishes to contest the disclosure. If the Receiving Party
                                          is compelled by law to disclose the Disclosing Party's Confidential Information as part of a civil proceeding to which the Disclosing Party is a party, and the Disclosing Party is not contesting the disclosure, the
                                          Disclosing Party will reimburse the Receiving Party for its reasonable cost of compiling and providing secure access to such Confidential Information.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <h4>LIMITATION OF LIABILITY</h4>
                                      <ul>
                                        <li>
                                          <strong>
                                            LIMITATION OF LIABILITY. IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF BUILDER TOGETHER WITH ALL OF ITS AFFILIATES ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED THE PROGRAM FEES PAID BY PARTNER IN THE
                                            12 MONTHS PRECEDING THE FIRST INCIDENT OUT OF WHICH THE LIABILITY AROSE. THE FOREGOING LIMITATION WILL APPLY WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY. NOTWITHSTANDING THE
                                            FOREGOING, THE ABOVE LIMITATIONS ON LIABILITY SHALL NOT APPLY TO BUILDER'S INDEMNIFICATION OBLIGATIONS UNDER THE SECTION (INDEMNIFICATION BY BUILDER) BELOW.
                                          </strong>
                                        </li>
                                        <li>
                                          <strong>
                                            EXCLUSION OF CONSEQUENTIAL AND RELATED DAMAGES. IN NO EVENT WILL BUILDER OR ITS AFFILIATES HAVE ANY LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT FOR ANY LOST PROFITS, REVENUES, GOODWILL, OR INDIRECT,
                                            SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER, BUSINESS INTERRUPTION OR PUNITIVE DAMAGES, WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY, EVEN IF BUILDER OR ITS AFFILIATES HAVE
                                            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF BUILDER OR ITS AFFILIATES' REMEDY OTHERWISE FAILS OF ITS ESSENTIAL PURPOSE. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.
                                          </strong>
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <h4>INDEMNIFICATION</h4>
                                      <ul>
                                        <li>
                                          <strong>Indemnification by Partner. </strong>Partner will defend Builder against any claim, demand, suit or proceeding made or brought against Builder by a third party (i) alleging that Partner's products or
                                          services, or any data that Partner enters into the Services or the Partner Community, infringe the intellectual property rights of, or have otherwise harmed, such third party; (ii) based upon a representation made by
                                          Partner to such third party; or (iii) based upon a Partner's breach of this Agreement (each a "<strong>Claim Against Builder</strong>"), and will indemnify Builder from any damages, attorney fees and costs finally
                                          awarded against Builder as a result of, or for any amounts paid by Builder under a settlement approved by Partner in writing of, a Claim Against Builder, provided Builder (a) promptly gives Partner written notice of
                                          the Claim Against Builder, (b) gives Partner sole control of the defense and settlement of the Claim Against Builder (except that Partner may not settle any Claim Against Builder unless it unconditionally releases
                                          Builder of all liability), and (c) gives Partner all reasonable assistance, at Partner's expense<strong>.</strong>
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <strong>Indemnification by Builder. </strong>Builder will defend Partner against any claim, demand, suit or proceeding made or brought against Partner by a third party alleging that the Services, or the Partner
                                          Community, infringes or misappropriates the intellectual property rights of such third party (a "<strong>Claim Against Partner</strong>"), and will indemnify Partner from any damages, attorney fees and costs finally
                                          awarded against Partner as a result of, or for amounts paid by Partner under a settlement approved by Builder in writing of, a Claim Against Partner, provided Partner (a) promptly gives Builder written notice of the
                                          Claim Against Partner, (b) gives Builder sole control of the defense and settlement of the Claim Against Partner (except that Builder may not settle any Claim Against Partner unless it unconditionally releases
                                          Partner of all liability), and (c) gives Builder all reasonable assistance, at Builder's expense. If Builder receives information about an infringement or misappropriation claim related to the Services or Partner
                                          Community Builder may in its discretion and at no cost to Partner (i) modify the Services or Partner Community so that they are no longer claimed to infringe or misappropriate, (ii) obtain a license for Partner's or
                                          Customer's (as applicable) continued use of that Service in accordance with this Agreement; or (iii) terminate any of Partner's or Customer's (as applicable) rights for that Service upon thirty (30) days' written
                                          notice and refund Partner or Customer (as applicable) any prepaid fees covering the remainder of the term of the terminated Services. The above defense and indemnification obligations do not apply to the extent a
                                          Claim Against Partner arises from (i) Content, a Non-Builder Application or Partner's breach of this Agreement, the Documentation or applicable Order Forms; or (ii) the use or combination of the Services, the Partner
                                          Community, or any part thereof with software, hardware, data, or processes not provided by Builder, if the Services or Partner Community, or use thereof, would not infringe without such combination
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <strong>Exclusive Remedy. </strong>This "Indemnification" section states the indemnifying party's sole liability to, and the indemnified party's exclusive remedy against, the other party for any type of claim
                                          described in this section
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <h4>Warranties; Disclaimers and Remedies</h4>
                                      <p>
                                        <strong>
                                          EXCEPT AS EXPRESSLY SET FORTH HEREIN, BUILDER MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND REGARDING THE SERVICES, THE PARTNER PROGRAM (INCLUDING WITHOUT LIMITATION THE PARTNER SERVICES, PARTNER COMMUNITY,
                                          MARKETPLACE AND PROGRAM BENEFITS), WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                          PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW INCLUDING WITH RESPECT TO THE PERFORMANCE, FUNCTIONALITY, QUALITY, BENEFITS OR AVAILABILITY OF ALL OF THE FOREGOING. CONTENT AND BETA
                                          SERVICES ARE PROVIDED "AS IS," AND AS AVAILABLE EXCLUSIVE OF ANY WARRANTY WHATSOEVER. IN NO EVENT WILL BUILDER BE LIABLE TO PARTNER (OR TO ANY INDIVIDUAL OR ENTITY AFFILIATED WITH PARTNER) FOR ANY CLAIM, LOSS OR
                                          DAMAGE ARISING OUT OF THE OPERATION OR AVAILABILITY OF THE SERVICES, THE PARTNER SERVICES OR ANY OTHER BUILDER PRODUCT OR SERVICE, MADE AVAILABLE, ACCESSED OR USED AS PART OF PARTNER'S PARTICIPATION IN THE PARTNER
                                          PROGRAM
                                        </strong>
                                        .
                                      </p>
                                    </li>
                                    <li>
                                      <h4>Relationship of the Parties</h4>
                                      <p>
                                        This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between Partner and Builder, notwithstanding the use of the term "partner" in this Agreement. Neither
                                        Party will represent that it has any authority to assume or create any obligation, express or implied, on behalf of the other Party, nor to represent the other Party as agent, employee, franchisee, or in any other
                                        capacity. There are no third-party beneficiaries to this MPA. Partner shall not make any proposals, promises, warranties, guarantees, or representations on Builder's behalf or in Builder's name.
                                      </p>
                                    </li>
                                    <li>
                                      <h4>Services Feedback</h4>
                                      <p>
                                        Partner grants Builder a worldwide, perpetual, irrevocable, royalty-free, transferable, sublicensable, license to use and incorporate into its services any suggestion, enhancement request, recommendation, correction or
                                        other feedback provided by Partner relating to the operation of Builder's or its Affiliate's services.
                                      </p>
                                    </li>

                                    <li>
                                      <h4>Term, Termination &amp; Renewal</h4>
                                      <ul>
                                        <li>
                                          <strong>Term</strong>. This Agreement starts on the Effective Date and shall remain in effect unless terminated as set forth herein, provided that if Partner joins a Partner Program(s) and its participation in all
                                          such Partner Programs terminates, this Agreement shall automatically terminate as of the end date of its participation in the last Partner Program.
                                        </li>
                                        <li><strong>Termination for Cause. </strong>Either Party may immediately terminate this MPA upon written notice to the other Party if:</li>
                                      </ul>
                                      <ul>
                                        <li>the other Party becomes the subject of a petition in bankruptcy or other proceeding relating to insolvency, or makes an assignment for the benefit of creditors,&nbsp;</li>
                                        <li>the other Party publicly announces (including by reporting it in SEC filings) that it has reached agreement to acquire or be acquired by the terminating Party's competitor,&nbsp;</li>
                                        <li>the other Party breaches its confidentiality obligations under this MPA or infringes or misappropriates the terminating Party's intellectual property rights,&nbsp;</li>
                                        <li>
                                          it determines, based on one or more Customer or prospective Customer complaints, that the other Party's actions or statements creates a significant risk of harm to the terminating Party's reputation or customer
                                          relationships,&nbsp;
                                        </li>
                                        <li>the other Party has committed fraud or misrepresentation with respect to entering into and/or the performance of this Agreement,&nbsp;</li>
                                        <li>a Party learns of circumstances that give it reason to believe that the other Party has engaged in illegal conduct or unethical business practices in connection with performance of this Agreement,&nbsp;</li>
                                        <li>
                                          the other Party, or any of its owners or employees responsible for providing services under this Agreement have become the target of an investigation or prosecution by any governmental authority for alleged
                                          corruption or other violation of laws, or&nbsp;
                                        </li>
                                        <li>
                                          the other Party has violated the Compliance Section<strong> </strong>(Compliance with Applicable Laws) above including, in the case of Builder, Partner's violating Builder's rights under trademark and copyright laws
                                          and/or ICANN policies and procedures governing domain names.
                                        </li>
                                      </ul>
                                      <p>
                                        Subject to the foregoing, either Party may terminate this MPA upon thirty (30) days' written notice to the other Party of such other Party's material breach if the breach is not cured during that period. Builder may
                                        suspend Partner's assigned Program Type Program Benefits during any period in which Partner is in breach of this Agreement, including its payment obligations. Termination of this MPA for cause shall be in addition to,
                                        and not in lieu of either Party's other legal rights and remedies.
                                      </p>
                                      <ul>
                                        <li>
                                          <strong>Termination for Convenience. </strong>Subject to the Section<strong> </strong>(Effect of Termination) below, Builder may terminate this MPA for convenience upon thirty (30) days' written notice to Partner..
                                        </li>
                                        <li>
                                          <strong>Effect of Termination. </strong>Upon termination or expiration of this MPA, Partner shall cease to be a participant in the Partner Program and all of Partner's rights to receive the Program Benefits detailed
                                          in this Agreement, and to use Builder's Property shall cease. If Builder terminates for convenience under the Section<strong> </strong>(Termination for Convenience) or Partner terminates for cause under the Section
                                          <strong> </strong>(Termination for Cause), Builder will refund the pro-rated portion of any pre-paid Program Fees covering the period following such termination. Provisions that survive termination or expiration
                                          include those relating to limitation of liability, payment, and others which by their nature are intended to survive. For clarity, expiration or termination of this MPA will not relieve Partner of its obligation to
                                          pay the portion of the Program Fees associated with its participation in the Partner Program leading up to the effective date of the expiration or termination.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <h4>Cooperation on Disputes</h4>
                                      <p>
                                        Partner shall reasonably cooperate with Builder in regard to any inquiry, dispute or controversy in which Builder may become involved and of which Partner may have knowledge, including with respect to disclosure of
                                        relevant documents and financial information, and interviews of Partner's personnel. Such obligation shall continue after the expiration or termination of this Agreement.
                                      </p>
                                    </li>
                                    <li>
                                      <h4>Entire Agreement</h4>
                                      <p>
                                        Partner agrees that this MPA and the information which is incorporated into this MPA by written reference (including reference to information contained in a URL and/or referenced policies and/or guides), or any
                                        applicable Order Form for Program Fees or the purchase of certain Program Benefits, or addendum attached hereto, constitutes the complete agreement between the Parties relating to Partner's participation in the Partner
                                        Program. This Agreement supersedes and replaces any prior representations, written or oral, regarding Partner's participation in the Partner Program as well as any other online or click-through agreement that Partner may
                                        have previously entered into with Builder governing Partner's participation in the Partner Program before the MPA version date provided above. If any term of this Agreement is found to be invalid or unenforceable, the
                                        remaining provisions will remain effective. To the extent of any conflict or inconsistency between the provisions in the body of this MPA and any addendum or exhibit hereto, the terms of such addendum or exhibit shall
                                        prevail. To the extent of any conflict or inconsistency between the provisions in the body of this MPA and any Order Form, the terms of the Order Form shall prevail. The Parties agree that any term or condition stated in
                                        a Partner purchase order or in any other Partner order documentation (excluding Order Forms) is void. This MPA and any Order Form that Builder and Partner enter into may not be modified and the rights and restrictions
                                        may not be altered or waived except in a writing signed or accepted electronically by the party against whom the modification, amendment or waiver is to be asserted, provided however, that Builder may modify or amend the
                                        Program Policies from time to time as permitted therein.
                                      </p>
                                    </li>
                                    <li>
                                      <h4>Assignment</h4>
                                      <p>
                                        Neither Partner nor Builder may assign any rights or obligations under this Agreement without the prior written consent of the other (not to be unreasonably withheld or delayed), provided either Party may assign this
                                        Agreement without consent of the other Party in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of the assigning Party's assets not involving a direct competitor of
                                        the other Party.
                                      </p>
                                    </li>
                                    <li>
                                      <h4>Parties, Legal Notices, Governing Law and Jurisdiction</h4>
                                      <ul>
                                        <li>
                                          <strong>General. </strong>The Builder entity that Partner is contracting with under this Agreement, the address to which Partner should direct legal notices arising under or relating to this Agreement, the law that
                                          will apply in any lawsuit arising out of or in connection with this Agreement, and which courts can adjudicate any such lawsuit, depend on where Partner is domiciled as follows:
                                        </li>
                                      </ul>
                                      <div>
                                        <div className="table-container">
                                          <table className="not-prose">
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <p><strong>If Partner is domiciled in:</strong></p>
                                                </td>
                                                <td>
                                                  <p><strong>The Builder entity entering into this Agreement is:</strong></p>
                                                </td>
                                                <td>
                                                  <p><strong>Notices should be addressed to</strong></p>
                                                </td>
                                                <td>
                                                  <p><strong>Governing law is:</strong></p>
                                                </td>
                                                <td>
                                                  <p><strong>Courts with exclusive jurisdiction:&nbsp;</strong></p>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <br />
                                                  <p><span>The United States of America, including North America (NA) and Latin America&nbsp; (LATAM)</span></p>
                                                </td>
                                                <td>
                                                  <p><span>Engineer.ai Corp., a Delaware corporation</span></p>
                                                </td>
                                                <td>
                                                  <p><span>26 S. Rio Grande Street, Suite 2072 Salt Lake City, Utah 84101, USA</span></p>
                                                </td>
                                                <td>
                                                  <br />
                                                  <p><span>Delaware and controlling United States federal law</span></p>
                                                </td>
                                                <td>
                                                  <p><span>Delaware, U.S.A.</span></p>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <p><span>India, including Asia-Pacific (APAC)</span></p>
                                                </td>
                                                <td>
                                                  <p><span>Engineer.ai India Private Limited</span></p>
                                                </td>
                                                <td>
                                                  <p><span>77B, Sector 18, IFFCO Road, Gurugram, Haryana</span></p>
                                                  <p><span>India</span></p>
                                                </td>
                                                <td>
                                                  <p><span>India</span></p>
                                                </td>
                                                <td>
                                                  <p><span>Delhi, India</span></p>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <p><span>All Other, including Europe (EU), and Middle East and North Africa (MENA)</span></p>
                                                </td>
                                                <td>
                                                  <p><span>Engineer.ai Global Limited</span></p>
                                                </td>
                                                <td>
                                                  <p><span>15 Westferry Circus Canary Wharf London</span></p>
                                                  <p><span>E14 4HD</span></p>
                                                  <p><span>United Kingdom</span></p>
                                                </td>
                                                <td>
                                                  <p><span>England</span></p>
                                                </td>
                                                <td>
                                                  <p><span>England and Wales</span></p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <ul>
                                        <li>
                                          <strong>Manner of Giving Notice. </strong>Except as otherwise specified in this Agreement, all notices, permissions and approvals hereunder will be in writing and will be effective upon (a) personal delivery, (b) the
                                          second business day after mailing, or (iii) except for notices of termination or an indemnifiable claim ("<strong>Legal Notices</strong>"), which shall clearly be identifiable as Legal Notices, the day of sending by
                                          email. Notices to Partner shall be addressed to the contact designated by Partner for Partner's relevant partner account, and in the case of billing-related notices, to the relevant billing contact designated by
                                          Partner. Notices to Builder that are not Legal Notices shall be addressed to the entity Partner is contracting with, as described above, and to the attention of the Partner Program Manager.
                                        </li>
                                        <li>
                                          <strong>Governing Law and Jurisdiction. </strong>Each Party agrees to the applicable governing law above without regard to choice or conflicts of law rules, and to the exclusive jurisdiction of the applicable courts
                                          above.
                                        </li>
                                      </ul>
                                    </li>
                                  </ol>

                                  <p><strong>SCHEDULE B: DEFINITIONS</strong></p>
                                  <p>
                                    "<strong>Affiliate</strong>" means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity. For purposes of this definition, "<strong>Control</strong>" means
                                    direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.
                                  </p>
                                  <p>
                                    "<strong>Beta Services</strong>" means Services or functionality that may be made available to Customer or Partner to try at its option at no additional charge which is clearly designated as beta, pilot, limited release,
                                    developer preview, non-production, evaluation, or by a similar description.
                                  </p>
                                  <p>"<strong>Builder</strong>" means the Builder.ai company as set forth in<strong>Section </strong>(Parties, Legal Notices, Governing Law and Jurisdiction) of this MPA.</p>
                                  <p>
                                    "<strong>Confidential Information</strong>" means all confidential information disclosed by a Party ("<strong>Disclosing Party</strong>") to the other Party ("<strong>Receiving Party</strong>"), whether orally or in
                                    writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. Builder's Confidential Information includes,
                                    but is not limited to, the Marketplace; Builder's and third party applications; any non-public information Partner has access to through the Partner Community; the Services; Customer Data to which Partner has access
                                    through Builder's systems by virtue of participating in the Partner Program; and the terms and conditions of this Agreement. Partner's Confidential Information includes, but is not limited to Partner Applications and
                                    Partner's business and marketing plans, technology and technical information; products designs; and business processes. Confidential Information of each Party includes the discussions regarding the partner relationship.
                                    However, Confidential Information shall not include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party, (ii) was known to the Receiving
                                    Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or
                                    (iv) was independently developed by the Receiving Party without breach of any obligations owed to the Disclosing Party. Notwithstanding the foregoing, the protections set forth in this Agreement for Customer Data remain
                                    in full force and effect even where such Customer Data meets the criteria in (i) - (iv) above.
                                  </p>
                                  <p>
                                    "<strong>Content</strong>" means information obtained by Builder from publicly available sources or its third party content providers and made available to Customer or Partner through the Services, Beta Services or
                                    pursuant to an Order Form, as more fully described in the Documentation.
                                  </p>
                                  <p>"<strong>Customer</strong>" means an individual or entity (including its Affiliates) that has entered into a Master Subscription Agreement with Builder and one or more Order Form(s) to purchase Services.</p>
                                  <p>"<strong>Customer Data</strong>" means electronic data and information submitted by or for Customer to the Services, excluding Content and Non-Builder Applications.</p>
                                  <p>"<strong>Documentation</strong>" means the applicable compliance documentation, and its usage guides and policies, as updated from time to time.</p>
                                  <p>"<strong>Effective Date</strong>" of this Agreement will be the date on which Partner accepts this Agreement as set forth above.</p>
                                  <p>"<strong>Marketplace</strong>" means Builder's online directory of (i) applications that interoperate with the Services and (ii) consulting partner services.</p>
                                  <p>
                                    <strong>"Non-Builder Application" </strong>means a Web-based, mobile, offline or other software application functionality that is provided by Customer, Partner or a third party and interoperates with a Service,
                                    including, for example, an application that is developed by or for Customer or Partner, is listed on an online directory, catalog or marketplace of applications that interoperate with the Services, including, for
                                    example, the Marketplace, or is identified as Builder Labs or by a similar designation.
                                  </p>
                                  <p>"<strong>Order Form</strong>" means, depending on the context in which it is used herein,</p>
                                  <ol>
                                    <li>
                                      the ordering documents that are entered into between Partner and Builder from time to time to effect Partner's payment of Program Fees associated with its participation in the Partner Program or receipt of certain
                                      additional Program Benefits, including any addenda to such ordering documents; Order Forms submitted by Partner shall be deemed incorporated herein by reference;
                                      <p className="or-txt"><em>or</em></p>
                                    </li>
                                    <li>ordering documents or online order, such as a Buildcard, specifying the Services to be provided to a Customer and associated with a Customer's purchase of Services from Builder.</li>
                                  </ol>                                  
                                  <p>"<strong>Participation Qualifications</strong>" means the Program Type requirements set forth in the applicable Program Type's Program Policies.</p>
                                  <p>"<strong>Partner</strong>" means (i) a company or other legal entity, or (ii) an individual acting on their own behalf, who has agreed to this Agreement.</p>
                                  <p>
                                    "<strong>Partner Community</strong>" means a partner portal or website that Builder may make available to certain participants in the Partner Program (which may have varying levels of access based on the Partner's
                                    assigned Program Type and Partner Tier) to facilitate their participation in the Partner Program, including the ability to access online training courses, log support cases, and collaborate with Builder employees and
                                    other Builder Partners.
                                  </p>
                                  <p>
                                    "<strong>Partner Program</strong>" means, collectively, the Program Benefits and rights and obligations of Partner and Builder that are associated with the Program Types described in the Program Policies. The Partner
                                    Program does not provide distribution rights to the Partner for the Services, nor does it contemplate any kind of reseller relationship between Builder and Partner, which are governed by separate and additional Builder
                                    agreements and application processes.
                                  </p>
                                  <p>
                                    "<strong>Partner Services</strong>" means the online, Web-based applications and platform to facilitate Partner's partner relationship with Builder and available via builder.ai and/or other designated websites, that are
                                    provided to Partner in accordance with this Agreement and/or Partner's participation in a Program Type, including associated offline components, but excluding any Non-Builder Applications. Partner Services includes, but
                                    is not limited to, the Partner Community, Marketplace and Partner training resources.
                                  </p>
                                  <p>"<strong>Partner Tier</strong>" means Partner's level in certain Program Types. Partner Tiers are described more fully in the Program Policies and are subject to change from time to time.</p>
                                  <p>
                                    "<strong>Partner User</strong>" shall mean an individual who is authorized by Partner to use the Services or Partner Services (including Partner Community) that Builder makes available to Partner, and to whom Partner
                                    (or, when applicable, Builder at Partner's request) has supplied a user identification and password (for Services or Partner Services utilizing authentication). Partner Users may include, for example, employees,
                                    consultants, contractors and agents of Partner, and third parties with which Partner transacts business.
                                  </p>
                                  <p>
                                    "<strong>Program Benefits</strong>" means the materials and/or services that may be provided to Partner under this Agreement as part of Partner's participation in a Program Type. Certain Program Benefits may be subject
                                    to payment of additional fees.
                                  </p>
                                  <p>"<strong>Program Fees</strong>" means collectively, any fees that Partner must pay Builder for participation in a Program Type, or for Program Benefits, as further described in the Program Policies.</p>
                                  <p>
                                    "<strong>Program Policies</strong>" means the terms describing the Partner Program, Program Types, Partner Tiers, Program Benefits, and other policies governing Partner's participation in the Partner Program, as set
                                    forth in this MPA.&nbsp;
                                  </p>
                                  <p>
                                    "<strong>Program Type</strong>" means a category or sub-category of the Partner Program that has a particular scope and particular set of Program Benefits, as set forth in this MPA and the Program Policies. Program Types
                                    may have multiple Partner Tiers.
                                  </p>
                                  <p>
                                    "<strong>Services</strong>" means the products and services that are ordered by Customer under an Order Form (or otherwise provided to Partner in accordance with this Agreement) and made available online by Builder,
                                    including associated Builder offline or mobile components, as described in the Documentation. Services exclude Content and Non-Builder Applications.
                                  </p>
                                </div>
                              </div>
                              {/* <div className="btn-wrap">
                                <Button className="custom-btn green-btn" style={{ textTransform: 'none' }}>
                                  Download document
                                </Button>
                              </div> */}
                            </div>
                          </TabPanel>
                        </Box>
                      </Box>
                    </Grid>
                    <Box className="btn-wrap">
                      <Button className="custom-btn gray-btn" style={{ textTransform: 'none' }} onClick={() => this.closeTermsConditionsModal()}>Cancel</Button>
                      <Button className="custom-btn green-btn" style={{ textTransform: 'none' }} onClick={()=>this.acceptAndContinue()}>Accept and continue</Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Grid>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
