import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import './topnav.css';
import { Avatar, Grid, Menu, MenuItem, Typography, Popover, List, ListItem, ListSubheader, Button, Collapse } from "@material-ui/core";
import { getEnviromentValue, getIntitals, getToken, removeAllToken, setToken } from "./commonUsage";
const notification = require('./notification.svg')
const side_arrow = require('./side_arrow.svg')
const side_arrow_color = require('./side_arrow_color.svg')
const thumbs_loss = require('./thumbs_loss.svg')
const thumbs_won = require('./thumbs_won.svg')
const up_purple = require('./up_purple.svg')
const uploaded = require('./uploaded.svg')
const close_red = require('./close_red.svg')
const empty_notification = require('./empty-notification.svg')
import { useRunEngine } from "../../blocks/utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../blocks/utilities/src/hooks/useBlockHelpers";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { trackEvent } from "./analytics";

const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
];

type Notification = {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "created_by": string,
        "headings": string,
        "contents": string,
        "app_url": string,
        "is_read": boolean,
        "read_at": string,
        "notification_type": string,
        "notification_type_id": number,
        "created_at": string,
        "updated_at": string,
        "partner_id": number,
        "partner_name": string
    }
}

const TopNavBar = ({ history, currentPage, subPage, backPage }: any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const image_url = getToken('profile_image') || ""
    const profile_name = getToken('profile_name') || ""
    const [anchorNotificationEl, setAnchorNotificationEl] = React.useState(null);
    const [openCollapse, setOpenCollapse] = React.useState(false);
    const [notifications, setNotifications] = React.useState<Notification[]>([]);
    const [page, setPage] = React.useState(1);
    const [isAllNotificationReaded, setIsAllNotificationReaded] = React.useState(false);
    const [loadMore, setLoadMore] = useState<boolean>(true)
    const [buUrl, setBuUrl] = useState<string>("")
    


    const fetchNotificationListId = useRef("")
    const fetchUserDetailId = useRef("")
    const markedAsReadIp = useRef("")
    const observer = useRef<any>(null);
    const selectedNotification = useRef<Notification | null>(null);
    const iframeRef=useRef<any>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        const element=document.getElementById("builderUniversity") as any
        if (element) {
            element.contentWindow.postMessage({ type: 'logout' }, '*');
        }
        setTimeout(()=>{
            removeAllToken()
            history.push('/')
        },1000)
    }

    const handleNotificationClick = (event: any) => {
        trackEvent("notifications_clicked","User clicks on the notifcations CTA",{dewey_code:"B3.17",flow:1})
        setAnchorNotificationEl(event.currentTarget);
    };

    const handleNotificationClose = () => {
        setAnchorNotificationEl(null);
    };

    const handleCollapseClick = () => {
        setOpenCollapse(!openCollapse);
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if (getToken('authToken') === null) {
            removeAllToken()
            if (params.has("user_library_id") || params.has('lead_id') || params.has('opp_id')) {
                const data = window.location.href.split('/')[3]
                localStorage.setItem('path', data)
            }
            history.push('/')
        }
    }, [getToken('authToken')])

    const openNotification = Boolean(anchorNotificationEl);
    const id = openNotification ? 'notification-popover' : undefined;

    const {
        sendBlockMessage,
        sendNetworkRequest,
        setReceiveCallback,
        subscribe,
        unsubscribeFromMessage,
    } = useRunEngine();
    const { extractNetworkResponse, hideKeyboard, isPlatformWeb } = useBlockHelpers();

    const fetchNotificationList = () => {
        const headers = {
            "Content-Type": "application/json",
            token: getToken('authToken'),
        };

        sendNetworkRequest(
            fetchNotificationListId,
            "GET",
            `/bx_block_notifications/notifications?page=${page}&per_page=10`,
            headers
        );
    }

    const markedAsRead = (param: string) => {
        const headers = {
            "Content-Type": "application/json",
            token: getToken('authToken'),
        };

        sendNetworkRequest(
            markedAsReadIp,
            "PUT",
            `bx_block_notifications/notifications${param}`,
            headers
        );
    }

    const markNotificationAsRead = (id?: number, markAll?: boolean) => {
        notifications.map(noti => {
            if (noti.attributes.id === id)
                noti.attributes.is_read = true
            if (markAll) {
                noti.attributes.is_read = true
            }
            return noti
        })

        let allReaded=false;
        notifications.forEach(notif=>{
            if(!notif.attributes.is_read)
                allReaded=true
        })
        setIsAllNotificationReaded(allReaded)
    }
    const [createIframe,setCreateIframe]=useState(true)

    useEffect(() => {
        setReceiveCallback(receive);
        // subscribedMessages.forEach((message) => subscribe(message));

        socketConnection()
        fetchUserDetail()
        const pathname=window.location.pathname
        if(pathname.includes('training')){
            setCreateIframe(false)
        }
        // return () => {
        //     subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
        // };
    }, []);

    useEffect(() => {
        if(localStorage.getItem("email"))
        fetchNotificationList();
        
    }, [page])

    const receive = async(from: string, message: Message) => {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const { apiRequestCallId, responseJson } =
                extractNetworkResponse(message);
            if (apiRequestCallId === fetchNotificationListId.current) {
                if (responseJson?.data?.length) {
                    setNotifications((prev: Notification[]) => {
                        const notifcationList = [...prev, ...responseJson.data]
                        let allReaded=false;
                        notifcationList.forEach(notif=>{
                            if(!notif.attributes.is_read)
                                allReaded=true
                        })
                        setIsAllNotificationReaded(allReaded)
                        return notifcationList
                    })
                    setLoadMore(true)
                } else {
                    setLoadMore(false)
                }
            }

            if (apiRequestCallId === markedAsReadIp.current && selectedNotification.current) {
                handleRedirect(
                    selectedNotification.current.attributes.notification_type,
                    selectedNotification.current.attributes.notification_type_id,
                    selectedNotification.current.attributes.headings,
                    selectedNotification.current.attributes.contents)
            }

            if (apiRequestCallId === fetchUserDetailId.current) {
                const sessionExist = await getStorageData("sessionExist") || "false"
                setBuUrl(`${responseJson.partner.data.attributes.bu_url}&sessionExist=${sessionExist}`)
                const name = responseJson.partner.data.attributes.name ?? responseJson.partner.data.attributes.first_name + " " + responseJson.partner.data.attributes.last_name
                setToken('profile_name', name)
                setToken('profile_image', `${responseJson.partner.data.attributes.partner_image.url}`)
                setToken('partner_role', responseJson.partner.data.attributes.role.name)
                setToken('partner_id', responseJson.partner.data.attributes.partner_id)
                setToken('user_id', responseJson.partner.data.attributes.id)
                setToken('login_count', responseJson.partner.data.attributes.login_count)
                setToken('email', responseJson.partner.data.attributes.email)
            }
        }
    };

    const handleRedirect = (type: string, id: number, contentType?: string, url?: string) => {
        if (type === 'lead') {
            window.location.href = `/lead/${id}`
        }
        if (type === 'opp') {
            window.location.href = `/deal/${id}`
        }
        if (type === 'library') {
            window.location.href = `/asset-library`
        }
    }

    const socketConnection = () => {
        const socket = new WebSocket(getEnviromentValue('SOCKET_URI') || "wss://develop.api.gtmpartners.builder.ai/cable");

        const subscriptionMessage = JSON.stringify({
            command: "subscribe",
            identifier: JSON.stringify({ channel: "PartnerNotificationChannel" })
        });

        socket.onopen = () => {
            console.log('WebSocket connection established');
            socket.send(subscriptionMessage);
            console.log('Subscription message sent:', subscriptionMessage);
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const user_id = localStorage.getItem('user_id')
            if (data.message?.partner_id == user_id) {
                setNotifications(prev => [{ id: "", type: "notification", attributes: data.message }, ...prev])
                setIsAllNotificationReaded(true)
            }
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

    }

    const lastItemRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && loadMore) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loadMore]
    );

    const fetchUserDetail = () => {
        const headers = {
            "Content-Type": "application/json",
            token: getToken('authToken'),
        };

        sendNetworkRequest(
            fetchUserDetailId,
            "GET",
            `bx_block_partner/partners/partner_show`,
            headers
        );
    }

    return (
        <Grid className="top-nav-container" container>
            <Grid item xs={10} md={10}>
                <Typography className="bredcrumb-text">
                    {
                        backPage ?
                            <>
                                <label style={{ cursor: 'pointer' }} onClick={() => history.push(`/${backPage.path}`)} className={"text-color display-flex"}><img className="side-arrow-img" src={side_arrow_color} />{backPage?.name}</label>
                            </>
                            :
                            <>
                                <label style={{ cursor: 'pointer' }} onClick={() => history.push(`/${currentPage.path}`)} className={subPage?.name !== undefined ? "text-color" : ""}>{currentPage?.name}</label> <label className="display-flex">{subPage?.name !== undefined ? <><img className="side-arrow-img" src={side_arrow} />{subPage?.name}</> : null}</label>
                            </>
                    }
                </Typography>
            </Grid>
            {createIframe&&<iframe
                src={buUrl}
                width={'100%'}
                height={'100%'}
                frameBorder="0" 
                style={{display:"none"}}
                id="builderUniversity"
                onLoad={async()=>{
                    await setStorageData("sessionExist","true")
                }}
            />}
            <Grid item xs={2} md={2} className="notification-container">
                    <Menu
                        style={{
                            top: '52px',
                            borderRadius: '8px'
                        }}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem className="menu-item-text" onClick={() => history.push('/user-profile')}>Profile</MenuItem>
                        <MenuItem className="menu-item-text" onClick={() => handleLogout()}>Logout</MenuItem>
                    </Menu>
                    
                <div className="notification-wrap">
                    <i onClick={(event) => {
                        handleNotificationClick(event)
                    }} aria-describedby={id}>
                        <img src={notification} className="top-notifcations" alt="Notifications" style={{ width: 26, height: 26, cursor: 'pointer' }} />
                        {isAllNotificationReaded && <span className="notification-badge"></span>}
                    </i>

                    <Popover
                        id={id}
                        open={openNotification}
                        anchorEl={anchorNotificationEl}
                        onClose={handleNotificationClose}
                        style={{
                            top: '42px',
                        }}
                        className="notification-popover"
                    >
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader className="notification-sublist" component="div" id="nested-list-subheader">
                                    <div className="title">Notifications</div>
                                    {notifications.length ? <Button
                                        disableRipple
                                        className="underline-link"
                                        onClick={() => {
                                            markNotificationAsRead(undefined, true)
                                            markedAsRead(`?allRead=true`)
                                        }}>
                                        Mark all as read
                                    </Button> : <></>}
                                </ListSubheader>
                            }
                            className="notification-list-wrap"
                        >
                            {
                                notifications.length ? notifications.map((notification, index) => {

                                    const time = moment(notification?.attributes?.created_at).fromNow(true).replace('minutes', 'min')
                                    const notificationType = notification?.attributes?.notification_type
                                    const status = notificationType === 'library' ? notification?.attributes?.headings : notification?.attributes?.headings&&JSON.parse(notification?.attributes?.headings)
                                    const currentStatus = notificationType === 'library' ? notification?.attributes?.headings : notification?.attributes?.headings&&JSON.parse(notification?.attributes?.headings)[1]
                                    const name = notification?.attributes?.contents
                                    const isReaded = !notification?.attributes?.is_read

                                    let image = up_purple
                                    let imageBG = ""
                                    let title = "Status is updated"


                                    if (notificationType === 'library') {
                                        image = uploaded
                                        title = "New asset is uploaded"
                                        imageBG = "purple-bg"
                                    }

                                    if (currentStatus === 'Disqualified') {
                                        image = close_red
                                        title = "Referral disqualified"
                                        imageBG = "red-bg"
                                    }
                                    else if (currentStatus === 'Closed won') {
                                        image = thumbs_won
                                        title = "Opportunity is won"
                                        imageBG = "green-bg"
                                    }
                                    else if (currentStatus === 'Closed lost') {
                                        image = thumbs_loss
                                        title = "Opportunity is lost"
                                        imageBG = "red-bg"
                                    }
                                    return (
                                        notifications.length === index + 1 ?
                                            <ListItem
                                                button
                                                className={`notification-list ${!isReaded ? '' : 'active'}`}
                                                key={index}
                                                onClick={() => {
                                                    selectedNotification.current = notification
                                                    markNotificationAsRead(notification.attributes.id)
                                                    markedAsRead(`?id=${notification.attributes.id}`)
                                                }}
                                                ref={lastItemRef}
                                            >
                                                <Item
                                                    image={image}
                                                    currentStatus={currentStatus}
                                                    isReaded={isReaded}
                                                    time={time}
                                                    title={title}
                                                    notificationType={notificationType}
                                                    name={name}
                                                    status={status}
                                                    imageBG={imageBG}
                                                />
                                            </ListItem> : <ListItem
                                                button
                                                className={`notification-list ${!isReaded ? '' : 'active'}`}
                                                key={index}
                                                onClick={() => {
                                                    selectedNotification.current = notification
                                                    markNotificationAsRead(notification.attributes.id)
                                                    markedAsRead(`?id=${notification.attributes.id}`)
                                                }}
                                            >
                                                <Item
                                                    image={image}
                                                    currentStatus={currentStatus}
                                                    isReaded={isReaded}
                                                    time={time}
                                                    title={title}
                                                    notificationType={notificationType}
                                                    name={name}
                                                    status={status}
                                                    imageBG={imageBG}
                                                />
                                            </ListItem>
                                    )
                                }) : <>
                                    <div className="empty-notification">
                                        <div className="empty-icon">
                                            <img src={empty_notification} alt="empty-icon" />
                                        </div>
                                        <div className="empty-tile">Currently nothing to report</div>
                                        <div className="empty-desc">Notifcations shall appear in this area once there is activity.</div>
                                    </div>
                                </>
                            }

                        </List>
                    </Popover>
                </div>
                {window.location.pathname !== '/user-profile' &&<Avatar className="avtar-block purple-bg" onClick={handleClick} src={`${image_url}`} alt="" style={{ width: 34, height: 34, marginLeft: 8, marginRight: 5, cursor: 'pointer', fontSize: '18px'}} >{getIntitals(profile_name?.charAt(0)?.toUpperCase())}</Avatar>}
            </Grid>
        </Grid>
    );
};
export default withRouter(TopNavBar);

const Item = ({ image, title, isReaded, time, currentStatus, notificationType, name, status, imageBG }: any) => {
    return (
        <div className="list-inner">
            <div className={`list-icon ${imageBG}`}>
                <img src={image} alt="close_red" />
            </div>
            <div className="list-content">
                <div className="list-heading">
                    <div className="list-title">{title}</div>
                    <div className={`list-time ${isReaded ? "is-read" : ""}`}>{time}</div>
                </div>
                {
                    currentStatus === 'Disqualified' ?
                        <div className="list-desc">
                            The referral <a href="#" className="purple-text">{`"${name}"`}</a> that you registered has been disqualified.
                        </div>
                        : currentStatus === 'Closed won' ?
                            <div className="list-desc">
                                Congratulations! The referral <a href="#" className="purple-text">{`"${name}"`}</a>  registered by you has been marked as closed won
                            </div> : currentStatus === 'Closed lost' ?
                                <div className="list-desc">
                                    The referral <a href="#" className="purple-text">{`"${name}"`}</a>  that you registered has lost.
                                </div>
                                : notificationType === 'library' ? <div className="list-desc">
                                    A new asset is available. Click here to view the asset library and see how this asset can benefit you.
                                </div>
                                    : <div className="list-desc">
                                        The referral <a href="#" className="purple-text">{`"${name}"`}</a> that you registered has moved from {`"${status[0]==="Open"?"Leads":status[0]}"`} to {`"${status[1]==="Open"?"Leads":status[1]}"`}.
                                    </div>
                }
            </div>
        </div>
    )
}
