import React from "react";

//Customizable Area Start
import { Box, Button, Typography, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import "./css/style.css";
import { siteLogo } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { Formik } from 'formik';
import { forgetPasswordSchema } from "../../../components/src/validations";
//@ts-ignore
import loginBg from '../assets/login_background.png';
//Customizable Area End

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>        
          <Box className="login-container-box"
          style={{
            backgroundImage: `url(${loginBg})`              
          }} >
            <Box className="login-container">
              <Formik
                enableReinitialize
                initialValues={{ emailValue: this.state.emailValue }}
                validationSchema={forgetPasswordSchema}
                onSubmit={() => {
                  this.setState({
                    errorMessage: null
                  })
                  this.sendInstructions()
                }}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <Box style={{ textAlign: 'center' }}>
                      <div className="logo-block">
                        <img src={siteLogo} className="login-partner-logo" />                        
                      </div>
                      <div className="title-block">
                        <Typography className="forgot-text">
                          Forgot password?
                        </Typography>
                        <Typography className="forgot-sub-text">
                          Enter your registered email address for reset instructions
                        </Typography>
                      </div>
                      <Box className="login-input-container">
                        <Box>
                          <TextField
                            name="emailValue"
                            size="small"
                            variant="filled"
                            data-test-id="txtInputEmail"
                            label={"Email"}
                            fullWidth={true}
                            value={formik.values.emailValue}
                            onChange={(e) => {
                              this.setEmailValue(e.target.value)
                              formik.handleChange(e)
                            }}
                            error={formik.touched.emailValue && Boolean(formik.errors.emailValue) || this.state.errorMessage}
                            helperText={formik.touched.emailValue && formik.errors.emailValue}
                          />
                        </Box>
                        {
                          this.state.errorMessage !== null &&
                          <Box>
                            <Typography className="error-text">
                              {this.state.errorMessage}
                            </Typography>
                          </Box>
                        }
                        <Box
                          style={{
                            marginTop:'24px'
                          }}
                        >
                          <Button
                            data-test-id={"btnEmailLogIn"}
                            variant="contained"                            
                            className="custom-btn green-btn"
                            fullWidth
                            type="submit"
                          >
                            <Typography className="btn-send-instructions">
                              Send instructions
                            </Typography>
                          </Button>
                        </Box>
                        <Box
                        style={{
                          marginTop:'24px',
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center'
                          }}
                        >
                          <Typography                          
                            onClick={() => this.backToLogin()}
                            className="btn-back-signin">
                            Back to sign in
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </form>
                )}

              </Formik>
            </Box>
          </Box>        
      </ThemeProvider>
      // Customizable Area End

    );
  }
}

// Customizable Area Start
