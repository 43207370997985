// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { NavigationStackProp } from "react-navigation-stack";
import { imgPasswordInVisible } from "./assets";
import { convertObjIntoArray, getColorsForStatus, getErrorResponse, getMemberErrorMessages, getStartAndEndDates, getToken, getYearMonthDate, removeAllToken } from "../../../components/src/commonUsage";
import { createRef } from "react";
import moment from "moment";
import { FormikProps } from "formik";
import { trackEvent } from "../../../components/src/analytics";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  inputData?: Array;
  rawInputData?: Array;
  showdropdown: boolean;
  showSubmitBtn: boolean;
  formId: string;
  headerTitle: string;
  successMsg: string;
  errorMessage: any;
  createNewCompany: boolean;
  leadInfo: any;
  companyArray: any;
  companyFilterArray: any;
  industryArray: any;
  selectedRows: any;
  selectAll: any;
  singleLeadInfo: any;
  currentPage: any;
  perPage: any;
  deleteModal: any;
  searchValue: any;
  sortValue: any;
  stagesValue: any;
  sortMenu: any;
  selectMenu: any;
  companyId: any;
  query: any;
  accordionStatus: any;
  csvData: any;
  uploadCsvModal: boolean;
  isUploaded: boolean;
  isDragging: boolean;
  fileSizeError: any;
  fileTypeError: any;
  uploadLeadsCount: any;
  selectedFile: any;
  confirmationModal: boolean;
  sampleCsvData: any;
  dealStatusFocus: boolean;
  companyNameFocus: boolean;
  industryFocus: boolean;
  countryFocus: boolean;
  leadRegistraion: boolean;
  dealRegistraton: boolean;
  countryData: any;
  countryCodes: any;
  currencyFocus: boolean;
  calendarMenu: any;
  calanderValue: any;
  dateRangeValue: any;
  openCalendar: any;
  tabValue: number;
  isTableLoading: boolean;
  empFocus: boolean;
  leadCountry: boolean;
  companyOptionModal: boolean;
  disableSameKey: boolean,
  headerError: any,
  designationArray: any,
  designationFocus: boolean;
  companyFilter: {
    search: string,
    page: number,
    per_page: number,
    isLoading: boolean
  }
  discardModal: boolean;
  discardToastOpen: boolean;
  value: any;
  viewDraft: any;
  calendarValue: any,
  isCalendarOpen: boolean,
  saveToastOpen: boolean;
  phoneFieldFocus: boolean;
  leadCountryCodeFocus: boolean;
  qualification: {
    productType: boolean;
    description: boolean;
    buget: boolean;
    date: boolean;
    paltform: boolean;
    dev: boolean;
    productIdea: boolean;
    appCategory: boolean;
    stageIdea: boolean;
  },
  referralInitValue: {
    deal_name: string;
    first_name: string;
    last_name: string;
    customer_email: string;
    lead_source: string;
    description: string;
    country: null,
    company_website: string;
    company_name: { name: string; id: string; },
    industry: null,
    phone_number: string;
    designation: string;
    employees: null,
    lead_country_code:
    {
      value: string;
      label: string;
      countryLabel: string;
    },
    lead_country: string;
    product_type: string;
    best_describe: string;
    budget: string;
    contact_date: string;
    platform_type: string;
    software_delivered: string;
    product_idea: string;
    app_category: string;
    idea_stage: string;
    description: string;
  }
  allFieldValues: {
    qualification_listing: {
      [key: string]: string[]
    }
  },
  refferalStatus: {
    isDraft: boolean,
    isSave: boolean,
    isDelete: boolean,
    disableAllField: boolean
  }
  validateEmail: {
    message: string,
    isDraft: boolean,
    lead: any,
    email: string,
  },
  draftLeads: {
    per_page: number,
    data: any,
    meta: any,
    deleteDraftId: number | null,
  },
  productTypeFocus: boolean,
  descriptionFocus: boolean,
  paltformFocus: boolean,
  devFocus: boolean,
  appCategoryFocus: boolean,
  stageIdeaFocus: boolean,
  bugetFocus: boolean,    
  navigateToDraft:{
    isClicked:boolean,
    navigate:boolean
  };
  rangeDatePicker: any;
  draftLoading: boolean;
  countryDropDown: boolean;
  search:string;
  runCheckCompanyExistFromDarft:number;
  feedbackModal: boolean;
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeadManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllInputsApiId: string = "";
  postInputDataApiId: string = "";
  createNewDealApiId: string = "";
  getAllLeadsApiId: string = "";
  getAllCompaniesApiId: string = "";
  getSingleLeadApiCallID: string = "";
  deleteLeadPointApiId: string = "";
  downloadCSVLeadID: string = "";
  fileInputRef: any;
  fileInputNewRef: any;
  uploadCsvApiCallId: string = "";
  checkCsvApiCallId: string = "";
  localStorage: any;
  getAllCountryiesApiId: string = "";
  getCompanySize: string = "";
  companyRef: any = createRef();
  formikRef: any = createRef();
  datePickerRef: any = createRef();
  allFieldValuesApiId: string = "";
  checkLeadEmailApiId: string = "";
  draftLeadApiId: string = "";
  deleteLeadApiId: string = "";
  checkCompanyExistApiId: string = "";
  calendarRef: any = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      alertData: {
        title: "",
        content: "",
        visible: false,
        btnData: [{}],
      },
      inputData: [],
      rawInputData: [],
      showdropdown: false,
      showSubmitBtn: false,
      formId: "",
      headerTitle: "",
      successMsg: "",
      errorMessage: null,
      createNewCompany: false,
      leadInfo: {
        "empty_state": true,
        "leads": [],
        "meta": {
          "total_pages": 0,
          "total_count": 0,
          "current_page": 0,
          "next_page": null,
          "previous_page": null
        },
        "csv_url": "",
        "open_deals": 0,
        "total_closed_lost_count": 0,
        "total_closed_won_count": 0,
        "total_deals": 0,
        "open_lead_graph": [],
        "total_lead_closed_lost_graph": [],
        "total_lead_closed_won_graph": [],
        "total_leads_graph": [],
        "leads_graph": [
          {
            "name": "Closed won",
            "count": 0
          },
          {
            "name": "Closed lost",
            "count": 0
          },
          {
            "name": "Open",
            "count": 0
          },
          {
            "name": "Disqualified",
            "count": 0
          },
          {
            "name": "In progress",
            "count": 0
          }
        ],
      },
      companyArray: [],
      companyFilterArray: [],
      industryArray: [],
      selectedRows: [],
      selectAll: false,
      singleLeadInfo: {
        "id": "",
        "form_id": "",
        "created_at": "",
        "updated_at": "",
        "form_data": "",
        "form_name": "",
        "first_name": "",
        "partner_id": "",
        "last_name": "",
        "customer_email": "",
        "conversion_value": "",
        "commission_percentage": "",
        "deal_status": "",
        "deal_name": "",
        "description": "",
        "source": "",
        "deal_currency": "",
        "deal_value": "",
        "company_lead": {
          "company_name": "",
          "industry": "",
          "company_website": "",
          "country": "",
          "state": "",
          "city": "",
        },
      },
      currentPage: 1,
      perPage: 10,
      deleteModal: false,
      searchValue: '',
      sortValue: 'Latest',
      stagesValue: 'All',
      selectMenu: null,
      sortMenu: null,
      companyId: "",
      query: "",
      accordionStatus: {
        deal: true, // Open the "Deal Information" accordion by default
        customer: true,
        other: true,
        leadCustomer: true,
        leadInformation: true,
        basicInformation: true,
        companyInformation: true,
        qualificationFields: true,
        demoCall: true,
        additionalNotes: true,
      },
      csvData:
      {
        "headers": [],
        "data": []
      },
      uploadCsvModal: false,
      isUploaded: false,
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
      uploadLeadsCount: 0,
      selectedFile: {},
      confirmationModal: false,
      isDealStatusFocused: false,
      sampleCsvData: "",
      dealStatusFocus: false,
      companyNameFocus: false,
      industryFocus: false,
      countryFocus: false,
      leadRegistraion: true,
      dealRegistraton: false,
      countryData: [],
      countryCodes: [],
      currencyFocus: false,
      calendarMenu: null,
      calanderValue: 'Last 90 days',
      dateRangeValue: getStartAndEndDates('Last 90 days'),
      openCalendar: false,
      tabValue: 0,
      isTableLoading: false,
      empFocus: false,
      leadCountry: false,
      companyOptionModal: false,
      disableSameKey: false,
      headerError: null,
      designationArray: [],
      designationFocus: false,
      companyFilter: {
        search: "",
        page: 1,
        per_page: 50,
        isLoading: false
      },
      viewDraft: null,
      calendarValue: null,
      isCalendarOpen: false,
      phoneFieldFocus: false,
      leadCountryCodeFocus: false,
      qualification: {
        productType: false,
        description: false,
        buget: false,
        date: false,
        paltform: false,
        dev: false,
        productIdea: false,
        appCategory: false,
        stageIdea: false,
      },
      referralInitValue: {
        deal_name: '',
        first_name: '',
        last_name: '',
        customer_email: '',
        lead_source: '',
        description: '',
        country: null,
        company_website: '',
        company_name: { name: "", id: "" },
        industry: null,
        phone_number: '',
        designation: '',
        employees: null,
        lead_country_code:
        {
          value: `+${1}`,
          label: `+${1}`,
          countryLabel: `+${1}`,
        },
        lead_country: null,
        product_type: "",
        best_describe: "",
        budget: "",
        contact_date: "",
        platform_type: "",
        software_delivered: "",
        product_idea: "",
        app_category: "",
        idea_stage: "",
        description: "",
      },
      allFieldValues: {
        qualification_listing: {
          industry_listing: [],
          budget_listing: [],
          categories_listing: [],
          stages_of_idea_listing: [],
          product_listing: [],
          software_delivered_listing: [],
          platform_listing: [],
          company_size_listing: [],
          best_describes_listing: []
        }
      },
      refferalStatus: {
        isDraft: false,
        isSave: false,
        isDelete: false,
        disableAllField: false
      },
      validateEmail: {
        message: "",
        isDraft: false,
        lead: null,
        email: ""
      },
      productTypeFocus: false,
      descriptionFocus: false,
      paltformFocus: false,
      devFocus: false,
      appCategoryFocus: false,
      stageIdeaFocus: false,
      bugetFocus: false,
      draftLeads:{
        per_page:10,
        data:[],
        meta:{},
        deleteDraftId:null
      },
      navigateToDraft:{
        isClicked:false,
        navigate:false
      },
      rangeDatePicker:[],
      draftLoading: false,
      countryDropDown: false,
      search: "",
      runCheckCompanyExistFromDarft:0,
      feedbackModal: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.calendarHandleChange = this.calendarHandleChange.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (getMemberErrorMessages(responseJson)) {
        removeAllToken()
        this.props.navigation.navigate('EmailAccountLoginBlock')
      } else {
        switch (apiRequestCallId) {
          case this.createNewDealApiId:
            this.handleCreateNewDealResponse(responseJson);
            break;
          case this.getAllLeadsApiId:
            const updateLeadInfo={...responseJson}
            if(!updateLeadInfo.empty_state){
              updateLeadInfo.total_leads_graph=convertObjIntoArray(updateLeadInfo.total_leads_graph)
              updateLeadInfo.total_lead_closed_lost_graph=convertObjIntoArray(updateLeadInfo.total_lead_closed_lost_graph)
              updateLeadInfo.open_lead_graph=convertObjIntoArray(updateLeadInfo.open_lead_graph)
              updateLeadInfo.close_won_garph_with_date_range=convertObjIntoArray(updateLeadInfo.close_won_garph_with_date_range)
            }
            this.handleResponse(updateLeadInfo, false, '', 'leadInfo');
            if (responseJson.empty_state !== true) {
              this.downloadDealsCSV();
            }
            break;
          case this.getAllCompaniesApiId:
            this.setState({
              companyFilter: { ...this.state.companyFilter, isLoading: false },
              companyArray: [...this.state.companyArray, ...responseJson.company_data]
            }, () => {
              this.filterOptions(this.state.companyFilter.search)
            });
            break;
          case this.getSingleLeadApiCallID:
            this.singleLeadRes(responseJson)
            break;
          case this.deleteLeadPointApiId:
            this.setState({
              selectAll: false,
              deleteModal: false,
              selectedRows: [],
              currentPage: 1,
              perPage: 10,
              leadInfo: {
                "meta": {
                  "total_count": 0,
                  "total_pages": 0,
                  "current_page": 0,
                  "next_page": null,
                  "previous_page": null
                },
                "leads": [],
                "csv_url": "",
                "open_deals": 0,
                "total_closed_won_count": 0,
                "total_closed_lost_count": 0,
                "total_deals": 0,
                "total_lead_closed_lost_graph": [],
                "total_lead_closed_won_graph": [],
                "open_lead_graph": [],
                "total_leads_graph": [],
                "leads_graph": [
                  {
                    "name": "Closed lost",
                    "count": 0
                  },
                  {
                    "name": "Closed won",
                    "count": 0
                  },
                  {
                    "name": "Disqualified",
                    "count": 0
                  },
                  {
                    "name": "Open",
                    "count": 0
                  },
                  {
                    "name": "In progress",
                    "count": 0
                  }
                ],
              },
            });
            this.getLeads('normal');
            break;
          case this.downloadCSVLeadID:
            this.handleResponse(responseJson, false, '', 'csvData');
            break;
          case this.getAllCountryiesApiId:
            const data=responseJson.countries.filter(x=>x.name.toLowerCase()!=="guernsey")
            this.setState({
              countryData: data.map((country) => ({
                label: country.name,
                value: country.name,
                ...country
              })),
              countryCodes: data
            })
            break;
          case this.allFieldValuesApiId:
            this.setState({ allFieldValues: responseJson.form_data })
            break;
          case this.checkLeadEmailApiId:
            this.leadEmailHandler(responseJson);
            break;
          case this.draftLeadApiId:
            if (responseJson.draft_leads) {
              this.setState({
                draftLeads: {
                  ...this.state.draftLeads,
                  data: responseJson.draft_leads,
                  meta: responseJson.meta,
                }
              });
            }
            this.setState({draftLoading:false})
            break;
          case this.deleteLeadApiId:
            this.setState({discardToastOpen:true})
            this.fetchDraftLeads();
            break;
          case this.checkCompanyExistApiId:
            this.checkCompanyExistRes(responseJson)
            break;
          default:
            // Handle the default case here
            break;
        }
      }
    }
    this.setState({
      isLoading: false,
    });
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.txtInputWebProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source =
        !this.txtInputProps.secureTextEntry && imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: !this.txtInputProps.secureTextEntry && imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgName = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgName.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgName);
  }

  // Customizable Area Start

  handleCreateNewDealResponse(responseJson) {
    this.setState({ createNewCompany: false })
    if (responseJson?.errors) {
      this.setState({
        errorMessage: getErrorResponse(responseJson),
      });
    } 
    if (this.state.refferalStatus.isSave) {
      localStorage.setItem('getStarted', 'true')
      trackEvent('register_referral_completed', "User fill in the customer details and clicks on 'Register referral' CTA", {dewey_code:"B3.3",flow:3});
      this.resetForm()
      this.openConfirmationModal();
      return
    }
    if(this.state.navigateToDraft.isClicked){
      this.setState({navigateToDraft:{isClicked:false,navigate:true}})
    }
    if(responseJson.data){
      localStorage.setItem("fromDraft", 'true')
      const lead=this.mappingLeadDataWhileSavingDraft(responseJson.data.attributes)
      lead['country']={label:lead.country,value:lead.country}
      lead['industry']={label:lead.industry,value:lead.industry}
      
      if(!lead.country || !lead.industry || lead.comapny_name?.id){
        this.setState({createNewCompany:true})
      }
      this.setState({
        referralInitValue:lead,
        draftLeads:{...this.state.draftLeads,deleteDraftId:responseJson.data.id}
      })
    }
    this.setState({draftLoading:false})
}


  handlePageChange(value) {
    this.setState({ currentPage: parseInt(value) }, () => {
      if(this.state.calanderValue==='All Time'){
        this.getLeads('filter');
      }else{
        this.getLeads('normal');
      }
    });
  }

  handleForms(value: any) {
    if (value === 'deal') {
      this.setState({
        dealRegistraton: true,
        leadRegistraion: false,
        errorMessage: null,
        createNewCompany: false
      })
    }
    if (value === 'lead') {
      this.setState({
        errorMessage: null,
        dealRegistraton: false,
        leadRegistraion: true
      })
    }
  }

  handleResponse(responseJson, navigaion, path, statedata) {
    if (!responseJson.errors) {
      if (navigaion) {
        this.props.navigation.navigate(path);
      } else {
        const updatedState = { ...this.state, [statedata]: responseJson };
        this.setState(updatedState,()=>{
          this.setState({isTableLoading:false})
        });
      }
    } else {
      // Check Error Response
      this.setState({
        errorMessage: getErrorResponse(responseJson)
      });
    }
  }

  getFormikError(error, erros) {
    return error && Boolean(erros)
  }
  getFormikHelperText(error, erros) {
    return error && erros
  }

  testValidateFunction() {
    this.setState({
      inputData: successResponse.data.inputFields,
    });
    this.validateFields();
    this.updateCheckBoxData(true, 2, "Cricket");
    this.updateCheckBoxData(false, 2, "Tennis");
    this.updateCheckBoxData(true, 2, "Football");
    this.updateInputData("text", 1);
    this.updateInputData("text area", 2);
    this.updateInputData("98989", 5);
    this.updateInputData("98989787634", 5);
    this.updateInputData("Male", 3);
    this.validateFields();
    this.updateInputData("", 3);
    this.validateFields();
    this.getInputType("radio");
    this.submitInputData({});
  }

  prepareInputData(responseData) {
    let temp = [];
    let submitBtn = false;

    responseData.forEach((item) => {
      switch (item.field_type?.toLowerCase()) {
        case "submit button":
          submitBtn = true;
          break;

        case "input":
        case "textarea":
        case "select":
          const isRequired =
            ["1", "true", true].includes(item.validations?.is_required) || false;

          const minLen = 1;

          const maxLen = 400;

          const type = this.getInputType(item.field_type?.toLowerCase());

          const errorMessage = item.label;

          temp.push({
            ...item,
            key: item.label,
            isRequired,
            validationRegex: item.validations?.validation_regex || "",
            minLen,
            maxLen,
            type,
            errorMessage,
            value: null,
          });
          break;
      }
    });

    this.setInputData(temp, submitBtn);

  }

  setInputData(tempData, submitBtn) {
    this.setState({
      inputData: tempData,
      showSubmitBtn: submitBtn,
    });
  }

  getInputType(type) {
    if (type?.includes("radio")) {
      return configJSON.typeRadio;
    }
    return type;
  }

  updateCheckBoxData(boolVal: boolean, index: number, idStr: string) {
    let temp = this.state.inputData;
    let prevValue = temp[index].value;

    temp[index].value = prevValue;
    temp[index].validationError = !prevValue || prevValue.length === 0;

    this.setState({
      inputData: temp,
    });
  }

  updateInputData(value: string, index: number) {
    let temp = this.state.inputData;
    temp[index].value = value;

    this.setState({
      inputData: temp,
    });

  }

  validateFields() {
    let temp = this.state.inputData;
    let errorCount = 0;
    let leadFields = [];

    this.setState({
      inputData: temp,
    });

    if (errorCount === 0) {
      this.submitInputData({
        form_id: this.state.formId,
        form_data: leadFields,
      });
    }
  }

  submitInputData = (leadData) => {
    try {
      this.setState({ isLoading: true });

      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postInputDataApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postInputData
      );

      const httpBody = {
        lead: leadData,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  getAllInputFields = async () => {
    try {
      this.setState({ isLoading: true });

      const header = {
        "Content-Type": configJSON.exampleApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAllInputsApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getInputDataEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  submitNewLead = async (values: any) => {
    if(this.state.refferalStatus.disableAllField){
      return
    }
    this.setState({ companyFilterArray: this.state.companyArray,draftLoading:true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewDealApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewLeadEndPoint
    );

    let entity_value = this.state.dealRegistraton ? 'deal' : 'lead';
    let deal_status = this.state.dealRegistraton ? values.deal_status.value : '';

    const defaultPayload = {
      entity_value: entity_value,
      first_name: values.first_name,
      last_name: values.last_name,
      customer_email: values.customer_email,
      deal_name: values.deal_name,
      conversion_value: 1.1,
      commission_percentage: 1.1,
      deal_status: deal_status,
      description: values.description,
      source: values.lead_source,
      deal_value: values.deal_value,
      phone_number: values.phone_number,
      country_code: values.lead_country_code.value,
      deal_currency: '$',
      designation: values.designation,
      lead_country: values.lead_country?.label || "",
      is_deleted: this.state.refferalStatus.isDelete,
      is_draft: this.state.refferalStatus.isDraft,
      is_saved: this.state.refferalStatus.isSave,

      // describe: values.describe,
      budget: values.budget.value,
      contact_date: values.contact_date,
      platform_type: values.platform_type?.value || "",
      software_delivered: values.software_delivered.value,
      product_idea: values.product_idea,
      app_category: values.app_category.value,
      idea_stage: values.idea_stage.value,
      best_describe: values.best_describe.value,
      product_type: values.product_type.value

    }

    const httpBody =
    this.state.createNewCompany ? {
      "data": {
        ...defaultPayload,
        lead_country:values.country?.label || "",
      },
      company_id: values.company_name?.id || "",
      "company_params": {
          "company_name": values.company_name?.name?.trim() || "",
          "city": '',
          "state": '',
          "country": values.country?.label || "",
          "company_website": values.company_website || "",
          "industry": values.industry?.value || "",
          "created_by_partner": "true",
          "company_size": values.employees?.value || ""
        },
      } :
      {
        "data": defaultPayload,
        'company_id': values.company_name?.id || ""
      }
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  createNewCompany = () => {
    this.setState({
      createNewCompany: true
    })
  }

  navigateToNewDeal() {
    this.props.navigation.navigate('RegisterReferral')
  }
  navigateToViewDeal() {
    this.props.navigation.navigate('LeadDashboard')
  }
  getLeads = async (type: any) => {
    this.setState({ isTableLoading: true })
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllLeadsApiId = requestMessage.messageId;
    if (type === 'normal') {
      this.setState({ isLoading: true });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createNewLeadEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue.replace(' & ', "%20%26%20")}&start_date=${getYearMonthDate(this.state.dateRangeValue[0])}&end_date=${getYearMonthDate(this.state.dateRangeValue[1])}`
      );
    }
    if (type === 'filter') {
      if (this.state.companyId != "") {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.createNewLeadEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue.replace(' & ', "%20%26%20")}&company_name=${this.state.query}&company_name_check=true`
        );

      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.createNewLeadEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue.replace(' & ', "%20%26%20")}`
        );
      }
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getCompanies = async () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCompaniesApiId = requestMessage.messageId;

    const { page, per_page, search } = this.state.companyFilter
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyListingEndPoint}?page=${page}&per_page=${per_page}&company_name=${search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  selectSingleLead(leadId: any, type: string) {
    if(type=="lead"){
      window.location.href=`/lead/${leadId}`
    }else{
      window.location.href=`/deal/${leadId}`
    }
  }
  getSingleLead(leadId: any,leadType?:string) {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleLeadApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSingleLeadEndPoint
    );

    const type = leadType || localStorage.getItem("type") 
    const payload = {}
    if (type == "opp") {
      payload["opp_id"] = leadId
    } else {
      payload["lead_id"] = leadId
    }
    const httpBody =
    {
      "data": payload

    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSearchValue(text) {
    this.setState({ companyId: "", searchValue: text, query: text, currentPage: 1 }, () => {
      if(this.state.calanderValue==='All Time'){
        this.getLeads('filter');
      }else{
        this.getLeads('normal');
      }
    })
  }


  toggleSelectAll = (e) => {
    const { leadInfo } = this.state;
    if (e.target.checked) {
      const allLeadIds = leadInfo.leads.map((row) => row);
      this.setState({ selectedRows: allLeadIds, selectAll: true });
    } else {
      this.setState({ selectedRows: [], selectAll: false });
    }
  };

  toggleSelectRow = (row) => {
    const { selectedRows } = this.state;
    const rowId = row;

    if (selectedRows.includes(rowId)) {
      this.setState({
        selectedRows: selectedRows.filter((id) => id !== rowId),
        selectAll: false,
      });
    } else {
      this.setState({
        selectedRows: [...selectedRows, rowId],
      });
    }

  };


  confirmationDeleteModal() {
    this.setState({
      deleteModal: true
    })
  }
  closeDeleteModal() {
    this.setState({
      deleteModal: false
    })
  }
  deleteLeads() {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteLeadPointApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewLeadEndPoint
    );


    const httpBody =
    {
      "data":
      {
        "id": this.state.selectedRows.map((item: any) => {
          return item.id
        })
      }

    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSortClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ sortMenu: event.currentTarget })
    }
  };

  handleSortClose = (text: any) => {
    this.setState({ sortValue: text, sortMenu: null,currentPage:1 }, () => {
      if(this.state.calanderValue==='All Time'){
        this.getLeads('filter');
      }else{
        this.getLeads('normal');
      }
    })
  };
  handleSelectClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ selectMenu: event.currentTarget })
    }
  };

  handleSelectClose = (text: any) => {
    this.setState({ stagesValue: text, selectMenu: null, currentPage: 1 }, () => {
      if(this.state.calanderValue==='All Time'){
        this.getLeads('filter');
      }else{
        this.getLeads('normal');
      }
    });
  };
  handleMenuClose = () => {
    this.setState({ sortMenu: null, selectMenu: null, viewDraft: null });
  }
  downloadDealsCSV() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadCSVLeadID = requestMessage.messageId;

    if (this.state.companyId != "") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createNewLeadEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue}&company_name=${this.state.query}&company_name_check=true&download_csv=true`
      );

    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createNewLeadEndPoint}?sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue}&download_csv=true`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getPageCount() {
    return this.state.leadInfo.leads.length
  }
  getIndeterminate() {
    return this.state.selectedRows.length > 0 &&
      this.state.selectedRows.length < this.state.companyArray.length
  }
  updateAccordionStatus = (sectionName, isOpen) => {
    this.setState((prevState) => ({
      accordionStatus: {
        ...prevState.accordionStatus,
        [sectionName]: isOpen,
      },
    }));
  };
  updateAllAccordionStatus = () => {
    this.setState({
      accordionStatus: {
        deal: true,
        customer: true,
        other: true,
        leadCustomer: true,
        leadInformation: true,
        basicInformation: true,
        companyInformation: true,
        qualificationFields: true,
        demoCall: true,
        additionalNotes: true,
      },
    })
  }
  getAccoridtionStatus = (type: any, errors: any) => {
    if (this.state.accordionStatus[type] || Object.keys(errors).length > 0) {
      return true; // Remove the period between accordionStatus and [type]
    } else {
      return false;
    }
  }
  getDealValudId(data, str1, str2) {
    return data === '' ? str1 : str2
  }
  getLeadCreatevalidation(value, str1, str2) {
    return value ? str1 : str2
  }
  openConfirmationModal() {
    this.setState({
      confirmationModal: true,
    });
  }
  openUploadCsvModal() {
    this.setState({
      uploadCsvModal: true,
      isUploaded: false,
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
      uploadLeadsCount: 0,
      selectedFile: {}
    })
  }
  closeConfirmationModal() {
    this.setState({
      confirmationModal: false,
      dealRegistraton: false,
      leadRegistraion: true
    })
  }
  closeUploadCsvModal() {
    this.setState({
      uploadCsvModal: false,
      isUploaded: false,
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
      uploadLeadsCount: 0,
      selectedFile: {}
    })
  }
  handleDragEnter = (e) => {
    e.preventDefault();
    this.setState({ isDragging: true });
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    this.setState({ isDragging: false });
  };

  handleDrop = (e) => {
    e.preventDefault();
    this.setState({
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
    });

    const files = e.dataTransfer.files;
    const validFiles = [];

    for (const file of files) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop(); // Get the file extension

      if (fileExtension === 'csv') {
        // Check file size (max 200 MB)
        if (file.size <= 200 * 1024 * 1024) { // 200 MB in bytes
          validFiles.push(file);
        } else {
          this.setState({
            fileSizeError: configJSON.fileSizeError,
          });
        }
      } else {
        this.setState({
          fileTypeError: configJSON.fileTypeError,
        });
      }
    }

    if (validFiles.length > 0) {
      this.setState({
        selectedFile: validFiles[0]
      })
      this.handleCheckCsv(validFiles[0]);
    } else {
      this.setState({
        fileTypeError: configJSON.fileTypeError,
      });
    }
  };



  handleCheckCsv = async (file: any) => {
    this.setState({ isLoading: true });

    const header = {
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkCsvApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.csvCheckEndPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData()
    formData.append('[file]', file);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  navigateToLeadDashboard = () => {
    this.props.navigation.navigate('LeadDashboard')
  }
  
  handleSampleClick = () => {
    console.log('CSV link clicked!');
  }
  handleFocus = (type: any) => {
    switch (type) {
      case 'deal_status':
        this.setState({
          dealStatusFocus: true
        });
        break;
      case 'company_name':
        this.setState({
          companyNameFocus: true
        });
        break;
      case 'industry':
        this.setState({
          industryFocus: true
        });
        break;
      case 'country':
        this.setState({
          countryFocus: true
        });
        break;
      case 'currency':
        this.setState({
          currencyFocus: true
        });
        break;
      case 'employees':
        this.setState({
          empFocus: true
        });
        break;
      case 'lead_country':
        this.setState({
          leadCountry: true
        });
        break;
      case 'designation':
        this.setState({
          designationFocus: true
        });
        break;
      case 'lead_country_code':
        this.setState({
          leadCountryCodeFocus: true
        });
        break;
      case 'productType':
        this.setState({
          productTypeFocus: true
        });
        break;
      case 'description':
        this.setState({
          descriptionFocus: true
        });
        break;
      case 'paltform':
        this.setState({
          paltformFocus: true
        });
        break;
      case 'dev':
        this.setState({
          devFocus: true
        });
        break;
      case 'appCategory':
        this.setState({
          appCategoryFocus: true
        });
        break;
      case 'stageIdea':
        this.setState({
          stageIdeaFocus: true
        });
        break;
      case 'buget':
        this.setState({
          bugetFocus: true
        });
        break;
      default:
    }

  }
  handleBlur = (type: any) => {
    switch (type) {
      case 'deal_status':
        this.setState({
          dealStatusFocus: false
        });
        break;
      case 'company_name':
        this.setState({
          companyNameFocus: false
        });
        break;
      case 'industry':
        this.setState({
          industryFocus: false
        });
        break;
      case 'country':
        this.setState({
          countryFocus: false
        });
        break;
      case 'currency':
        this.setState({
          currencyFocus: false
        });
        break;
      case 'employees':
        this.setState({
          empFocus: false
        });
        break;
      case 'lead_country':
        this.setState({
          leadCountry: false
        });
        break;
      case 'designation':
        this.setState({
          designationFocus: false
        });
        break;
      case 'phoneField':
        this.setState({
          phoneFieldFocus: false
        });
        break;
      case 'lead_country_code':
        this.setState({
          leadCountryCodeFocus: false
        });
        break;
      case 'productType':
        this.setState({
          productTypeFocus: false
        });
        break;
      case 'description':
        this.setState({
          descriptionFocus: false
        });
        break;
      case 'paltform':
        this.setState({
          paltformFocus: false
        });
        break;
      case 'dev':
        this.setState({
          devFocus: false
        });
        break;
      case 'appCategory':
        this.setState({
          appCategoryFocus: false
        });
        break;
      case 'stageIdea':
        this.setState({
          stageIdeaFocus: false
        });
        break;
      case 'buget':
        this.setState({
          bugetFocus: false
        });
        break;
      default:
    }
  }

  filterOptions = (value: string) => {
    const isPresent = this.state.companyArray.filter((item) => {
      return item.company_name && item.company_name.toLowerCase() === value.toLowerCase();
    }).length;
    this.setState({ disableSameKey: isPresent ? true : false })
    
    if (!value.length) {
      this.setState({ createNewCompany: false })
    }

    this.setState(prevState=>({runCheckCompanyExistFromDarft:prevState.runCheckCompanyExistFromDarft+1}),()=>{
      if(this.state.runCheckCompanyExistFromDarft===1){
        const leadFormData = localStorage.getItem('leadFormData')
        if (leadFormData) {
          const data = JSON.parse(leadFormData)
          if (data.company_name?.name?.length && (!data.company_name?.id || this.state.refferalStatus.isDraft)) {
            this.setState({
              createNewCompany: data.company_name?.id ? false : true
            })
            if(!data?.company_industry?.length || !data?.country?.length){
              this.setState({
                createNewCompany: true
              })  
            }
          }
        }
      }
    })
  }

  getCompanyDeals = (data: any) => {
    const { id, company_name } = data.attributes.company_lead
    this.setState({
      query: company_name,
      searchValue: company_name,
      companyId: id
    }, () => {
      this.getLeads('filter');
    })
  }
  getCountries = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCountryiesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleCalendarClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ calendarMenu: event.currentTarget,openCalendar:false });
    }
  };
  handleCalendarClose = () => {
    this.setState({ calendarMenu: null });
  }
  handleCalendarSelect = (text: any) => {
    this.setState({currentPage:1})
    if (text === "Custom...") {
      if (this.state.calanderValue === 'Custom...') {
        this.setState({
          openCalendar: true,
          calanderValue: text,
          calendarMenu: null,
          sortValue: 'Latest',
          stagesValue: 'All',
        })
      } else {
        this.setState({
          openCalendar: true,
          calanderValue: text,
          calendarMenu: null,
          sortValue: 'Latest',
          stagesValue: 'All',
        })
      }

    } else if (text === "All Time") {
      this.setState({
        calanderValue: text, calendarMenu: null, openCalendar: false, sortValue: 'Latest',
        stagesValue: 'All', isLoading: true,rangeDatePicker:[]
      }, () => {
        this.getLeads('filter')
      });
    } else {
      this.setState({
        calanderValue: text, calendarMenu: null, dateRangeValue: getStartAndEndDates(text), openCalendar: false, sortValue: 'Latest',
        stagesValue: 'All',rangeDatePicker:[]
      }, () => {
        this.getLeads('normal')
      });
    }
  };
  handleDateChange = (item: any) => {
    this.setState({ dateRangeValue: item });
  };
  closeCalander = () => {
    this.setState({
      isCalendarOpen: false,
      calendarValue: ""
    })
  }

  labelRenderer = (row: any) => {
    return getColorsForStatus(row.deal_status).label === 'Open' ? 'Leads' : getColorsForStatus(row.deal_status).label
  }

  createFile = () => {
    var content = 'This is a csv';

    var file = new File(["\ufeff" + content], 'myFile.csv', { type: "text/csv:charset=UTF-8" });

    url = window.URL.createObjectURL(file);
  }

  currencyCheck = (value: string | null) => {
    return value ? '$' : ''
  }

  handleLeadSubmit = (values: any, formik: any) => {
    if (values.company_name?.name?.length) {
      const item = this.state.companyArray.filter((item: any) => {
        return item.company_name && item.company_name.toLowerCase() === values.company_name.name.toLowerCase();
      })
      if (item.length) {
        formik.setFieldValue('company_name', { name: item[0].company_name, ...item[0] })
        values['company_name'] = { name: item[0].company_name,...item[0] }
        this.setState({ createNewCompany: false })
        this.submitNewLead(values)
      } else {
        this.setState({
          createNewCompany: true,
        })
        this.submitNewLead(values)
      }
    } else {
      this.submitNewLead(values)
    }
  }

  fetchEmployeeSize = () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompanySize = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companySizeEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.fileSizeError !== this.state.fileSizeError ||
      prevState.fileTypeError !== this.state.fileTypeError ||
      prevState.headerError !== this.state.headerError
    ) {
      setTimeout(() => {
        this.setState({
          fileSizeError: null,
          fileTypeError: null,
          headerError: null
        })
      }, 3000)
    }


  }

  onScroll = () => {
    if (this.companyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = this.companyRef.current;
      if (Math.round(scrollTop + clientHeight) === scrollHeight) {
        this.setState({
          companyFilter: {
            ...this.state.companyFilter,
            page: this.state.companyFilter.page + 1
          }
        })
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    const { page, search } = this.state.companyFilter
    if (prevState.companyFilter.page !== page || prevState.companyFilter.search !== search) {
      this.getCompanies()
    }
    if(prevState.navigateToDraft.navigate!==this.state.navigateToDraft.navigate){
      this.navigateToViewDraft()
    }
    if (prevState.navigateToDraft.isClicked !== this.state.navigateToDraft.isClicked) {
      if (this.formikRef.current) {
        const { values } = this.formikRef.current
        if(configJSON.emailRegex.test(values.customer_email) && this.state.navigateToDraft.isClicked)
          this.handleLeadSubmit(values, this.formikRef.current)
        else
          this.navigateToViewDraft()
      }
    }

    if (this.state.isCalendarOpen && !prevState.isCalendarOpen) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else if (!this.state.isCalendarOpen && prevState.isCalendarOpen) {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  }

  handleViewDraftClick = (event: any) => {
    this.setState({ viewDraft: event.currentTarget })
  };

  calendarHandleChange(date) {
    this.setState({
      calendarValue: date
    });
  }


  openDiscardModal(id: number) {
    this.setState({
      discardModal: true,
      draftLeads: { ...this.state.draftLeads, deleteDraftId: id }
    })
  }

  closeDiscardModal() {
    const fromDraft = localStorage.getItem("fromDraft")
    if (!fromDraft) {
      this.setState({
        draftLeads: { ...this.state.draftLeads, deleteDraftId: null }
      })
    }
    this.setState({
      discardModal: false,
    })
  }

  navigateToViewDraft() {
    this.props.navigation.navigate('ViewDrafts')
  }

  discardToastOpen = () => {
    this.deleteDraftLeads()
    this.setState({
      discardToastOpen: true,
      discardModal: false,
      draftLeads: { ...this.state.draftLeads, deleteDraftId: "" }
    });
  };

  discardToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ discardToastOpen: false });
  };

  saveToastOpen = () => {
    this.setState({
      saveToastOpen: true,
    });
  };

  saveToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ saveToastOpen: false });
  };

  fetchAllField = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allFieldValuesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allFieldEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dropDownValuesMapper = (data) => {
    return data.map(item => ({ label: item, value: item }))
  }

  persistFormData = () => {
    if(this.state.navigateToDraft.isClicked || this.state.navigateToDraft.navigate)
      return
    if (this.formikRef.current) {
      const { values } = this.formikRef.current
      if (configJSON.emailRegex.test(values.customer_email)) {
        this.handleLeadSubmit(values, this.formikRef.current)
        localStorage.removeItem('leadFormData')
        return
      }
      const { value } = values.lead_country_code
      values.lead_country_code = {
        value: value,
        label: value,
        countryLabel: value,
      }
      localStorage.setItem("leadFormData", JSON.stringify(values))
      localStorage.removeItem("fromDraft")
    }
  }

  checkLeadEmail = (email?:string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkLeadEmailApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.validateEmailApi}?email=${email??this.state.validateEmail.email}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  leadEmailHandler = (data: any) => {
    if (data?.error) {
      const fromDraft = localStorage.getItem("fromDraft")
      if (data.lead && !data.lead.is_saved) {
        if (!fromDraft) {
          this.setState({
            refferalStatus: { ...this.state.refferalStatus, disableAllField: true, isDraft: true },
            validateEmail: { ...this.state.validateEmail,message: data.message, isDraft: true, lead: data.lead[0] },
          })
        }
      } 
      else {
        this.setState({
          validateEmail: { ...this.state.validateEmail,message: data.message, isDraft: false },
          refferalStatus: { ...this.state.refferalStatus, disableAllField: true }
        })
      }
    } 
    if(data?.error===false) {
      this.setState({
        validateEmail: { ...this.state.validateEmail,message: "", isDraft: false },
        refferalStatus: { ...this.state.refferalStatus, disableAllField: false, isDraft: true }
      })
      if(this.formikRef.current)
        this.handleLeadSubmit(this.formikRef.current.values,this.formikRef.current)
    }
  }

  fetchDraftLeads = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.draftLeadApiId = requestMessage.messageId;

    const { per_page } = this.state.draftLeads

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.draftLeadsApi}?page=${this.state.currentPage}&per_page=${per_page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteDraftLeads = (id?: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteLeadApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteDraftApi}?draft_id=${this.state.draftLeads.deleteDraftId || id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  navigateToEditLead = (lead: any) => {

    localStorage.setItem("leadFormData", JSON.stringify(this.mappingLeadData(lead)))
    localStorage.setItem("fromDraft", 'true')
    this.props.navigation.navigate("RegisterReferral")
  }

  mappingLeadData = (lead: any) => {
    const value = lead.country_code
    lead.lead_country_code = {
      value: value,
      label: value,
      countryLabel: value,
    }

    lead['company_name']={
      name:lead.company_name,
      id:lead.company_id
    }

    lead['contact_date']=lead.contact_date?moment(lead.contact_date).format("DD/MM/YYYY"):""

    lead['lead_country']={
      label:lead.lead_country,
      value:lead.lead_country
    }

    lead['budget'] = { label: lead.budget, value: lead.budget }
    lead['platform_type'] = { label: lead.platform_type, value: lead.platform_type }
    lead['software_delivered'] = { label: lead.software_delivered, value: lead.software_delivered }
    lead['app_category'] = { label: lead.app_category, value: lead.app_category }
    lead['idea_stage'] = { label: lead.idea_stage, value: lead.idea_stage }
    lead['best_describe'] = { label: lead.best_describe, value: lead.best_describe }
    lead['product_type'] = { label: lead.product_type, value: lead.product_type }
    return lead
  }

  editDraft = () => {
    const lead = this.mappingLeadData(this.state.validateEmail.lead)
    localStorage.setItem("fromDraft", 'true')
    this.setState({
      referralInitValue: lead,
      refferalStatus: { disableAllField: false, isDraft: true, isDelete: false, isSave: false },
      validateEmail: { message: "", isDraft: false, lead: [] },
      draftLeads: { ...this.state.draftLeads, deleteDraftId: this.state.validateEmail.lead.id }
    })
  }

  isEmpty = (state: string) => {
    return state?.length ? state : "-"
  }

  dropDownOnFocus=()=>{
    return this.handleFocus(this.state.createNewCompany ? "country" : "lead_country")
  }

  dropDownInBlur=()=>{
    return this.handleBlur(this.state.createNewCompany ? "country" : "lead_country")
  }

  phoneNumberClass=(phone:string,error:any,className:string)=>{
    if(phone?.length && error){
      return className
    }
    return ""
  }

  deleteDisableDrafts=()=>{
    const data=this.state.draftLeads.data
    if(data.length){
      const disabledData=data.filter((data:any)=>data.disable)
      disabledData.forEach((data:any)=>{
        this.deleteDraftLeads(data.id)
      })
    }
  }

  resetForm=()=>{
    this.setState({draftLoading:false,draftLeads:{...this.state.draftLeads,deleteDraftId:null}})
    localStorage.removeItem("fromDraft")
    localStorage.removeItem("leadFormData")
    if (this.formikRef.current) {
      this.formikRef.current.resetForm()
      this.setState({
        referralInitValue: {
        deal_name: '',
        first_name: '',
        last_name: '',
        customer_email: '',
        lead_source: '',
        country: null,
        company_website: '',
        company_name: { name: "", id: "" },
        industry: null,
        phone_number: '',
        designation: '',
        employees: null,
        lead_country_code:
        {
          value: `+${1}`,
          label: `+${1}`,
          countryLabel: `+${1}`,
        },
        lead_country: "",
        product_type: "",
        best_describe: "",
        budget: "",
        contact_date: "",
        platform_type: "",
        software_delivered: "",
        product_idea: "",
        app_category: "",
        idea_stage: "",
        description: "",
      },
      refferalStatus: {
        isDraft: false,
        isSave: false,
        isDelete: false,
        disableAllField: false
      },
      validateEmail: {
        message: "",
        isDraft: false,
        lead: null,
        email: ""
      },
      companyFilter: {
        search: "",
        page: 1,
        per_page: 50,
        isLoading: false
      }
    })
    }
  }

  isLeadDashboardLoaded=()=>{
    return this.state.isLoading || this.state.isTableLoading
  }
  
  setFieldValueWithOutSpace=(name:string,text:string,formik:FormikProps<any>)=>{
    if(text.charAt(0)!==" ")
      formik.setFieldValue(name,text)
  }

  emailValidateError=(formik:FormikProps<any>)=>{
    return this.getFormikError(formik.touched.customer_email, formik.errors.customer_email)
    || this.state.validateEmail?.message?.length ? true : false
  }

  mappingLeadDataWhileSavingDraft = (lead: any) => {
    const value = lead.country_code
    lead.lead_country_code = {
      label: value,
      value: value,
      countryLabel: value,
    }
    lead['budget'] = { label: lead.budget, value: lead.budget }

    lead['company_name']={
      name:lead?.company_lead?.company_name ?? "",
      id:lead?.company_lead?.id ?? ""
    }
    lead['best_describe'] = { label: lead.best_describe, value: lead.best_describe }

    lead['contact_date']=lead.contact_date?moment(lead.contact_date).format("DD/MM/YYYY"):""

    lead['lead_country']={
      label:lead.lead_country,
      value:lead.lead_country
    }

    lead['software_delivered'] = { label: lead.software_delivered, value: lead.software_delivered }
    lead['product_type'] = { label: lead.product_type, value: lead.product_type }
    lead['platform_type'] = { label: lead.platform_type, value: lead.platform_type }
    lead['app_category'] = { label: lead.app_category, value: lead.app_category }
    lead['idea_stage'] = { label: lead.idea_stage, value: lead.idea_stage }
    return lead
  }

  loadDataFromLocal=()=>{
    const leadFormData = localStorage.getItem('leadFormData')
    if (leadFormData) {
      const data = JSON.parse(leadFormData)
      this.setState({
        referralInitValue: data,
        calendarValue: data.contact_date ? moment(data.contact_date, "DD/MM/YYYY").toDate() : "",
        validateEmail:{...this.state.validateEmail,email:data.customer_email}
      })
      if(configJSON.emailRegex.test(data.customer_email))
      this.checkLeadEmail(data.customer_email)
      if (data.company_name?.name?.length) {
        this.setState({
          createNewCompany: data.company_name?.id ? false : true
        })
      }

      const fromDraft = localStorage.getItem("fromDraft")
      if (fromDraft) {
        this.setState({
          draftLeads: { ...this.state.draftLeads, deleteDraftId: data.id },
          refferalStatus: { isDelete: false, isDraft: true, isSave: false, disableAllField: false }
        })
      }
    }
  }

  singleLeadRes=(responseJson:any)=>{
    if(responseJson?.errors?.length){
      this.props.navigation.navigate('LeadDashboard')
    }else{
      this.handleResponse(responseJson?.leads[0], false, '', 'singleLeadInfo');
    }
  }
  
  phoneBorderColor=(formik:any)=>{
    return this.getFormikError(formik.touched.phone_number||formik.touched.countryCode, formik.errors.phone_number||formik.errors.countryCode)?"#D50000":"#C0C3CE"
  }

  getCountryFlag=(code:string)=>{
    return this.state.countryData.filter(x=>x.value==code.replace("+",""))[0]?.code??"us" 
  }

  filterCountryData=()=>{
    if (this.state.search === '')
      return this.state.countryData;
    const filterBasedOnName=this.state.countryData.filter((country: any) => {
      return country.name.toLowerCase().includes(this.state.search.toLowerCase())
    })
    const filterBasedOnCode=this.state.countryData.filter((country: any) => {
      const code=`+${country.value}`
      return code.toLowerCase().includes(this.state.search.toLowerCase())
    })
    return [...filterBasedOnName,...filterBasedOnCode]
  }
  
  checkCompanyExist=(search:string)=>{
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkCompanyExistApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyListingEndPoint}?company_name=${search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleClickOutside = (event) => {
    if (this.calendarRef.current && !this.calendarRef.current.contains(event.target)) {
      this.setState({isCalendarOpen:false})
    }
  };

  filterCountryData=()=>{
    if (this.state.search === '')
      return this.state.countryData;
    
    const filterBasedOnCode=this.state.countryData.filter((country: any) => {const code=`+${country.value}`
      return code.toLowerCase().includes(this.state.search.toLowerCase())})
    
    const filterBasedOnName=this.state.countryData.filter((country: any) => {return country.name.toLowerCase().includes(this.state.search.toLowerCase())})
    return [...filterBasedOnName,...filterBasedOnCode]
  }

  checkCompanyExistRes=(responseJson:any)=>{
    const companyies = responseJson.company_data
    if (this.formikRef.current && companyies?.length) {
      const exist = companyies.filter(company => company.company_name.toLowerCase() === this.formikRef.current.values.company_name.name.toLowerCase().trim())

      if (exist.length) {
        this.formikRef.current.setFieldValue('company_name', { id: exist[0].id, name: exist[0].company_name })
        this.formikRef.current.setFieldValue("lead_country", null)
        this.formikRef.current.setFieldValue("country", null)
        this.setState({ createNewCompany: false })
      } else {
        this.setState({ createNewCompany: true })
        this.formikRef.current.setFieldValue('employees', { label: "", value: "" })
        this.formikRef.current.setFieldValue("company_website", "")
        this.formikRef.current.setFieldValue("lead_country", null)
        this.formikRef.current.setFieldValue("industry", null)
        this.formikRef.current.setFieldValue("country", null)
      }
    } else {
      this.setState({ createNewCompany: true })
      this.formikRef.current.setFieldValue('employees', { label: "", value: "" })
      this.formikRef.current.setFieldValue("company_website", "")
      this.formikRef.current.setFieldValue("lead_country", null)
      this.formikRef.current.setFieldValue("industry", null)
      this.formikRef.current.setFieldValue("country", null)
    }
  }
  // Customizable Area End
}

let successResponse = {
  data: {
    inputFields: [
      {
        errorMessage: "First Name is required",
        field_type: "Text",
        form_field_id: 1,
        isRequired: false,
        key: "First Name",
        label: "First Name",
        maxLen: 400,
        minLen: 1,
        placeholder: "First Name",
        type: "text",
        validationRegex: "",
        value: "",
        validations: {
          is_required: "0",
          min_length: null,
          max_length: null,
        },
      },
      {
        errorMessage: "Description is required",
        field_type: "Textarea",
        form_field_id: 2,
        isRequired: false,
        key: "Description",
        label: "Description",
        maxLen: 400,
        minLen: 1,
        placeholder: "Description",
        type: "textarea",
        validationRegex: "",
        value: "",
        validations: {
          is_required: "0",
        },
      },
      {
        errorMessage: "Favourite Sports is required",
        field_type: "Checkbox",
        form_field_id: 3,
        isRequired: false,
        key: "Favourite Sports",
        label: "Favourite Sports",
        maxLen: 400,
        minLen: 1,
        type: "checkbox",
        validationRegex: "",
        value: null,
        validations: {
          is_required: "0",
        },
        options: [
          {
            label: "Cricket",
            name: "Cricket",
            value: "Cricket",
            option_id: "Cricket",
          },
          {
            label: "Football",
            name: "Football",
            value: "Football",
            option_id: "Football",
          },
          {
            label: "Tennis",
            name: "Tennis",
            value: "Tennis",
            option_id: "Tennis",
          },
        ],
      },
      {
        errorMessage: "Gender is required",
        field_type: "Radio button",
        form_field_id: 4,
        isRequired: true,
        key: "Gender",
        label: "Gender",
        maxLen: 400,
        minLen: 1,
        type: "radio",
        validationRegex: "",
        value: [],
        validations: {
          is_required: "0",
        },
        options: [
          { label: "Male", name: "Male", value: "Male", option_id: "Male" },
          {
            label: "Female",
            name: "Female",
            value: "Female",
            option_id: "Female",
          },
          { label: "Other", name: "Other", value: "Other", option_id: "Other" },
        ],
      },
      {
        form_field_id: 5,
        field_type: "Submit button",
        value: "Submit",
        type: "submit button",
        validations: {
          is_required: "0",
        },
      },
      {
        errorMessage: "Phone Number is required",
        field_type: "Text",
        form_field_id: 6,
        isRequired: true,
        key: "Phone Number",
        label: "Phone Number",
        maxLen: 20,
        minLen: 1,
        placeholder: "Phone Number",
        type: "text",
        value: "",
        validationRegex:
          "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$",
        validations: {
          is_required: "1",
        },
      },
      {
        lable: "Gender",
        key: "gender",
        isRequired: true,
        validationRegex: "",
        value: "",
        errorMessage: "Please select gender",
        validationError: false,
        minLen: 5,
        maxLen: 16,
        type: "dropdown",
        options: [
          {
            id: 1,
            label: "Male",
          },
          {
            id: 2,
            label: "Female",
          },
          {
            id: 3,
            label: "Other",
          },
        ],
      },
      {
        action_email_id: 2,
        field_type: "Action email",
        action_email: "alex@gmail.com",
        type: "action email",
      },
    ],
  },
};
